import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEpochInfo } from '../../../api/staker';
import { StakeActionThunkConfig } from './common';
import { loadSolanaStake } from './loadSolanaStake';
import { EpochInfo } from '@solana/web3.js';

export const REFRESH_INTERVAL_MS = 60_000;

export const loadEpochInfo = createAsyncThunk<
  EpochInfo,
  void,
  StakeActionThunkConfig
>('sol/loadEpochInfo', async (_, { dispatch, getState }) => {
  if (getState().stake.pollingEnabled) {
    // refresh the epoch info every 60 seconds
    setTimeout(() => {
      dispatch(loadEpochInfo());
    }, REFRESH_INTERVAL_MS);
  }

  const newEpochInfo = await getEpochInfo();
  const oldEpoch = getState().stake?.epochInfo?.epoch;
  if (oldEpoch && oldEpoch < newEpochInfo.epoch) {
    // new epoch! trigger a stake state update
    dispatch(loadSolanaStake());
  }

  return newEpochInfo;
});
