import { FC } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';

const CheckIcon = (
  <CheckCircleIcon className="h-12 w-12 text-pl-green" aria-hidden="true" />
);

export const StakingSuccessPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => {
  return (
    <InfoPopup
      open={open}
      setOpen={setOpen}
      {...props}
      message={
        <>
          <p>Your stake is in Warmup state.</p>
          <p>You may now safely leave the page.</p>
        </>
      }
      title={'Staking Complete'}
      Icon={CheckIcon}>
      {children}
    </InfoPopup>
  );
};
