import { FC } from 'react';
import { PublicKey } from '@solana/web3.js';

export const AddressView: FC<{ address: PublicKey }> = ({ address }) => (
  <>
    <span className="hidden lg:inline">
      {address
        .toBase58()
        .replace(/(.{5}).*(.{5})/, (_, p1, p2) => `${p1}...${p2}`)}
    </span>
    <span className="lg:hidden">
      {address
        .toBase58()
        .replace(/(.{3}).*(.{3})/, (_, p1, p2) => `${p1}...${p2}`)}
    </span>
  </>
);
