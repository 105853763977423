import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';

type Props = Omit<
  ConfirmProps,
  'okText' | 'cancelText' | 'message' | 'title' | 'onOK'
>;
export const SolanaWalletConnectingInfo: FC<Props> = ({ ...props }) => (
  <Confirm
    {...props}
    message={`Please follow the steps in the wallet dialog to set up your Powerledger wallet`}
    title="Connecting Powerledger Wallet"
    okText="OK"
    onOK={() => {}}
    okOnly={true}
  />
);
