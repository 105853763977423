import { useMemo } from 'react';
import config from '../config';

export const useEthereumExplorer = (
  txHash: string | undefined
): string | undefined => {
  const networkUrlPrefix = useMemo(() => {
    switch (config.ethereum.network) {
      case 'mainnet':
        return '';
      default:
        return config.ethereum.network + '.';
    }
  }, [config.ethereum.network]);

  return txHash
    ? `https://${networkUrlPrefix}etherscan.io/tx/${txHash}`
    : undefined;
};
