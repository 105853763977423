import { FC, useMemo, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { classNames } from '../../lib/util';
import { Validator, viewName } from '../../lib/validator';
import { ValidatorOption } from './ValidatorOption';
import { useValidators } from '../../lib/hooks/useValidators';

const byCommission = (a: Validator, b: Validator) => {
  if (a.commission < b.commission) return -1;
  if (a.commission > b.commission) return 1;
  return Math.random() - 0.5;
};

type SelectorProps = {
  selected?: Validator;
  setSelected: (validator: Validator) => void;
  disabled?: boolean;
};
export const ValidatorSelector: FC<SelectorProps> = ({
  selected,
  setSelected,
  disabled = false
}) => {
  const validators = useValidators();
  // Sort validators by commission (increasing)
  // Shuffle validators to promote more even distribution of allocated funds
  const sortedValidators = useMemo(
    () => validators?.sort(byCommission),
    [validators]
  );

  return (
    <div className="inline-flex items-center justify-between mb-3">
      <span className="font-body font-medium">to Validator</span>
      <span className="mr-5"></span>
      <Listbox value={selected} onChange={setSelected} disabled={disabled}>
        <div className="mt-1 relative">
          <Listbox.Button
            className={classNames(
              'flex-1 py-2',
              'rounded-lg w-56 h-12 ',
              'relative border border-gray-300 rounded-md',
              'shadow-sm pl-3 pr-10 py-2 text-left cursor-default',
              disabled
                ? 'bg-gray-600 cursor-not-allowed'
                : 'bg-white focus:outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300',
              'sm:text-sm'
            )}
            disabled={disabled}>
            <span className="block truncate">
              {selected ? viewName(selected) : 'Select'}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base validator-listbox">
              {(sortedValidators || []).map((v) => (
                <ValidatorOption validator={v} key={v.address.toBase58()} />
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
