import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from './common';
import { loadEthStake } from './loadEthStake';
import { withdrawPLChain } from './withdrawPLChain';
import { UnlockActionPayload, UnlockResponse } from '../../withdraw';
import { loadSolanaStake } from './loadSolanaStake';

export const unlock = createAsyncThunk<
  UnlockResponse,
  UnlockActionPayload,
  ThunkConfig
>('eth/unlock', async (payload, { dispatch }) => {
  const response = await dispatch(withdrawPLChain(payload)).unwrap();

  // load the solana stake - it should be gone
  await dispatch(loadSolanaStake());

  // load the eth stake, which should now have an unstakeTimestamp
  await dispatch(loadEthStake(payload.provider)).unwrap();

  return response;
});
