import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { BigNumber } from '@ethersproject/bignumber';
import { minorPOWRToMajor } from '../../lib/ethereum/util';

/**
 * Displays a minor POWR amount as a POWR major denomination
 * @param lamports
 * @constructor
 */
export const POWRView: FC<{ minorPOWR: BigNumber; decimalScale?: number }> = ({
  minorPOWR,
  decimalScale = 5,
  ...props
}) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={decimalScale}
    value={minorPOWRToMajor(minorPOWR)}
    displayType="text"
    {...props}
  />
);
