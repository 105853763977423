export class WalletMismatchError extends Error {
  constructor() {
    super('Wallet Mismatch');
  }
}

export class WrongNetworkError extends Error {
  constructor() {
    super('Wrong ethereum network selected');
    this.name = 'WrongNetworkError';
  }
}
