import { FC, useMemo } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import * as React from 'react';
import { FeeView } from '../common/FeeView';
import { useUnlockFee } from '../../lib/hooks/useUnlockFee';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const UnlockStakeConfirm: FC<Props> = ({ ...props }) => {
  const { fees } = useUnlockFee();

  const message = useMemo(
    () => (
      <>
        <p>
          Please sign a transaction using your PLChain wallet to unlock your
          stake.
        </p>
        <p>Your stake will be available for withdrawal after seven days.</p>
        {fees && <FeeView fees={fees} />}
      </>
    ),
    [fees]
  );

  return (
    <Confirm
      {...props}
      message={message}
      title="Unlock Stake"
      okText="Continue"
    />
  );
};
