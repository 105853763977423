import { FC, useMemo, useState } from 'react';
import { ClockIcon, ExclamationIcon } from '@heroicons/react/solid';
import { Button } from '../common/Button';
import { ValidatorShortView } from '../common/ValidatorShortView';
import { UndelegateStakeConfirm } from '../modal/UndelegateStakeConfirm';
import { WithdrawStakeConfirm } from '../modal/WithdrawStakeConfirm';
import { StakeExceptionPopup } from '../modal/StakeExceptionPopup';
import { useStake } from '../../lib/state/context';
import { StakeSummary } from '../../lib/state/types';
import { toStakeSummary } from '../../lib/state/util';
import { POWRView } from '../common/POWRView';
import { InfoDialogIcon } from '../common/InfoDialogIcon';
import { WarmupInfoPopup } from '../modal/WarmupInfoPopup';
import { UnlockStakeConfirm } from '../modal/UnlockStakeConfirm';
import {
  remainingTime,
  remainingTimeInEpoch,
  timeToText
} from '../../lib/util';
import { signWithMetamask } from '../../lib/ethereum/util';
import { useWeb3React } from '@web3-react/core';
import { StakingSuccessPopup } from '../modal/StakingSuccessPopup';
import { showStakingSuccessMessage } from '../../lib/state/actions';
import { useAppDispatch } from '../../store';
import { EpochInfoPopup } from '../modal/EpochInfoPopup';
import { UndelegateCooldownInfoPopup } from '../modal/UndelegateCooldownInfoPopup';
import { UnlockCooldownInfoPopup } from '../modal/UnlockCooldownInfoPopup';
import { useValidators } from '../../lib/hooks/useValidators';

type StakeProps = {
  undelegate: () => void;
  unlock: () => void;
  withdraw: () => void;
};
const ActionButton: FC<StakeProps & { stake: StakeSummary }> = ({
  stake,
  undelegate,
  unlock,
  withdraw
}) => {
  switch (stake.status) {
    case 'DELEGATED':
    case 'WARMUP':
      return <Button onClick={undelegate}>Undelegate</Button>;
    case 'NOT_DELEGATED':
      return <Button onClick={unlock}>Unlock</Button>;
    case 'UNLOCKED':
      return (
        <Button data-testid="withdraw-button" onClick={withdraw}>
          Withdraw
        </Button>
      );
    case 'COOLDOWN_PLCHAIN':
    case 'COOLDOWN_ETHEREUM':
      return (
        <Button
          disabled
          data-testid="withdraw-button"
          onClick={withdraw}
          title="Please wait for cooldown to complete">
          Cooldown...
        </Button>
      );
    default:
      return <></>;
  }
};

export const Stake: FC = () => {
  // Step 1: Undelegate stake on PLChain
  const [undelegateConfirmOpen, setUndelegateConfirmOpen] = useState(false);
  // Step 2: Withdraw on PLChain and Unlock on Ethereum
  const [unlockConfirmOpen, setUnlockConfirmOpen] = useState(false);
  // Step 3: Withdraw on Ethereum
  const [withdrawConfirmOpen, setWithdrawConfirmOpen] = useState(false);

  const [exceptionPopupOpen, setExceptionPopupOpen] = useState(false);
  const { stake, triggerUndelegate, triggerUnlock, triggerWithdrawEthereum } =
    useStake();
  const { library } = useWeb3React();
  const dispatch = useAppDispatch();
  const validators = useValidators();

  if (!stake) return <></>;

  const stakeSummary = useMemo(
    () => toStakeSummary(stake, validators),
    [stake, validators]
  );

  const signAndUndelegate = async () => {
    const timestamp = Date.now().toString();
    const signature = await signWithMetamask(library, timestamp);
    if (signature) triggerUndelegate(timestamp, signature);
  };

  return (
    <div className="grid grid-cols-[1fr,2fr,1fr,2fr] gap-x-2 items-center">
      <UndelegateStakeConfirm
        open={undelegateConfirmOpen}
        setOpen={setUndelegateConfirmOpen}
        onOK={signAndUndelegate}
      />
      <UnlockStakeConfirm
        open={unlockConfirmOpen}
        setOpen={setUnlockConfirmOpen}
        onOK={triggerUnlock}
      />
      <WithdrawStakeConfirm
        open={withdrawConfirmOpen}
        setOpen={setWithdrawConfirmOpen}
        onOK={triggerWithdrawEthereum}
      />
      <StakeExceptionPopup
        open={exceptionPopupOpen}
        setOpen={setExceptionPopupOpen}
        stakeSummary={stakeSummary}
      />
      <h3 className="pr-5 md:block hidden">Staked: </h3>
      <div className="text-3xl inline-flex w-full items-center">
        <POWRView
          data-testid="stake-amount-view"
          minorPOWR={stakeSummary.stakedAmount}
        />
        <div className="ml-3 text-xl">POWR</div>
      </div>
      <h3 className="pr-5 md:block hidden">Rewards: </h3>
      <div className="text-3xl inline-flex w-full items-center">
        <POWRView
          data-testid="stake-rewards-view"
          minorPOWR={stakeSummary.rewards}
          decimalScale={2}
        />
        <div className="ml-3 text-xl">POWR</div>
        <InfoDialogIcon Popup={EpochInfoPopup} />
      </div>

      {stakeSummary.validator ? (
        <>
          <h3>to: </h3>
          <ValidatorShortView validator={stakeSummary.validator} />
        </>
      ) : (
        <>
          <h3>Undelegated</h3>
          <span />
        </>
      )}

      <div className="pl-2 mb-2 mt-2 w-36 col-span-2">
        <ActionButton
          stake={stakeSummary}
          withdraw={() => setWithdrawConfirmOpen(true)}
          undelegate={() => setUndelegateConfirmOpen(true)}
          unlock={() => setUnlockConfirmOpen(true)}
        />
      </div>
      {stakeSummary.status === 'WARMUP' ? (
        <>
          <div className="inline-flex text-sm mx-auto">
            <ClockIcon className="w-5 mr-1" />
            Warmup
            <InfoDialogIcon Popup={WarmupInfoPopup} />
          </div>
          <div className="w-full col-span-4 text-center font-light">
            Approximate remaining warmup time:{' '}
            {stake?.solanaStake?.estimatedTimeToActive
              ? timeToText(stake?.solanaStake?.estimatedTimeToActive * 1000)
              : '-'}
          </div>
        </>
      ) : (
        <></>
      )}

      {stakeSummary.status === 'COOLDOWN_ETHEREUM' ? (
        <>
          <div className="inline-flex text-sm mx-auto">
            <ClockIcon className="w-5 mr-2" />
            Cooldown
            <InfoDialogIcon Popup={UnlockCooldownInfoPopup} />
          </div>
          <div className="w-full col-span-4 text-center font-light">
            Remaining cooldown time:{' '}
            {stake?.ethStake.unstakeTimestamp
              ? timeToText(
                  remainingTime(stake?.ethStake.unstakeTimestamp * 1000)
                )
              : '-'}
          </div>
        </>
      ) : (
        <></>
      )}
      {stakeSummary.status === 'COOLDOWN_PLCHAIN' ? (
        <>
          <div className="inline-flex text-sm mx-auto">
            <ClockIcon className="w-5 mr-1" />
            Cooldown
            <InfoDialogIcon Popup={UndelegateCooldownInfoPopup} />
          </div>
          <div className="w-full col-span-4 text-center font-light">
            Approximate remaining cooldown time:{' '}
            {stake?.epochInfo
              ? timeToText(remainingTimeInEpoch(stake.epochInfo))
              : '-'}
          </div>
        </>
      ) : (
        <></>
      )}

      {stake?.showStakingSuccessMessage && (
        <StakingSuccessPopup
          open={stake.showStakingSuccessMessage}
          setOpen={(isOpen) => {
            dispatch(showStakingSuccessMessage(isOpen));
          }}
        />
      )}

      {stakeSummary.exception ? (
        <div className="col-span-4 text-sm text-red-700 justify-center">
          <ExclamationIcon
            className="w-5 mx-auto cursor-pointer"
            onClick={() => setExceptionPopupOpen(true)}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
