import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import React from 'react';
import { classNames } from '../../lib/util';
import { Validator, viewName } from '../../lib/validator';

const displayName = (validator: Validator) =>
  viewName(validator) + `- commission: ${validator.commission}%`;

export const ValidatorOption: React.FC<{ validator: Validator }> = ({
  validator
}) => (
  <Listbox.Option
    data-testid="validator-option"
    className={({ active }) =>
      classNames(
        active ? 'text-white bg-blue-400' : 'text-gray-900',
        'cursor-default select-none relative py-2 pl-3 pr-9'
      )
    }
    value={validator}>
    {({ selected, active }) => (
      <>
        <span
          data-testid="validator-option-name"
          className={classNames(
            selected ? 'font-semibold' : 'font-normal',
            'block whitespace-nowrap',
            validator.commission === 100 ? 'text-gray-500' : 'text-gray-900'
          )}>
          {displayName(validator)}
        </span>

        {selected ? (
          <span
            className={classNames(
              active ? 'text-white' : 'text-blue-600',
              'absolute inset-y-0 right-0 flex items-center pr-4'
            )}>
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);
