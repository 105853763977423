import { FC } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

export const StakeWithdrawn: FC = () => (
  <div className="grid pb-1">
    <CheckCircleIcon
      className="mx-auto h-20 w-20 text-green-600"
      aria-hidden="true"
    />
    <h3 className="mx-auto mb-2" data-testid="info-title">
      Stake Withdrawn
    </h3>
    <span className="mx-auto">Your stake has been withdrawn.</span>
    <span className="mx-auto">
      Note - you may not re-stake a withdrawn stake with the same wallet.
    </span>
  </div>
);
