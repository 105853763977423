import React, { FC } from 'react';
import { Validator } from '../../lib/validator';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { copyToClipboard } from '../../lib/util';
import { CheckIcon } from '@heroicons/react/solid';

export const ValidatorDetailView: FC<{ validator: Validator }> = ({
  validator
}) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyAddress = () => {
    copyToClipboard(validator.address.toBase58())
      .then(() => setIsCopied(true))
      .then(() => setTimeout(() => setIsCopied(false), 1000));
  };

  const CopyIcon = () =>
    isCopied ? (
      <CheckIcon className="ml-2 w-5 animate-ping" />
    ) : (
      <ClipboardCopyIcon
        className="ml-2 w-5 cursor-pointer"
        onClick={copyAddress}
      />
    );

  return (
    <div className="grid grid-cols-[1fr,3fr] gap-3">
      <div className="justify-self-end">Name</div>
      <div className="justify-self-start">{validator.name || '-'}</div>
      <div className="justify-self-end">Address</div>
      <div className="inline-flex justify-self-start">
        <div>{validator.address.toBase58() || '-'}</div>
        <CopyIcon />
      </div>
      {/*<div className="justify-self-end">Performance</div>*/}
      {/*<div>TODO</div>*/}
    </div>
  );
};
