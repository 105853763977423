import { PublicKey } from '@solana/web3.js';

export type EthereumNetwork = 'rinkeby' | 'ropsten' | 'mainnet' | 'goerli';
type Config = {
  endpoint: string;
  coinbase: PublicKey;
  ethereum: {
    network: EthereumNetwork;
    contract: {
      erc20: string;
      stake: string;
    };
    cooldownHours: number;
  };
  solana: {
    testWallets: boolean;
    // If the solana deposit transaction is not resolved within this time,
    // allow the user to trigger it via the UI
    // undefined = do not allow the user trigger
    waitBeforeAllowingManualStakeMs?: number;
  };
};

type Stage = 'prod' | 'preprod' | 'local' | 'ropsten';

const defaultConfig = {
  coinbase: new PublicKey('GrWQmpYdoBtSNwKicamkpyf4hStsxBCyu4C91KUu1nNf'),
  endpoint: 'https://api-staging.powerledger.io',
  ethereum: {
    network: 'goerli' as EthereumNetwork,
    contract: {
      erc20: '0xA064abbf8405480D67D0BebeC2FC9352155a76a4',
      stake: '0xe3b4d2590879517273883958603A20E931876f9c'
    },
    cooldownHours: 1
  },
  solana: {
    testWallets: true,
    waitBeforeAllowingManualStakeMs: 30_000 // allow user to trigger the solana stake if not auto-triggered after 30s
  }
};

const ropstenConfig = {
  coinbase: new PublicKey('GrWQmpYdoBtSNwKicamkpyf4hStsxBCyu4C91KUu1nNf'),
  endpoint: 'https://api-staging.powerledger.io',
  ethereum: {
    network: 'ropsten' as EthereumNetwork,
    contract: {
      erc20: '0x873dA9D6783AAaFb8Ff62C31B54F942E442295EC',
      stake: '0xE63e837D91fb47a87B248d7205076d93b9c1fD38'
    },
    cooldownHours: 1
  },
  solana: {
    testWallets: true,
    waitBeforeAllowingManualStakeMs: 30_000 // allow user to trigger the solana stake if not auto-triggered after 30s
  }
};

const stageConfig: Record<Stage, Config> = {
  local: {
    ...defaultConfig,
    endpoint: 'http://localhost:8080'
  },
  preprod: {
    ...defaultConfig
  },
  prod: {
    ...defaultConfig,
    coinbase: new PublicKey('6rcxzwxj6637Jdk1Nme3Pcs6Je6Dr3hFBHDyHXngRqtA'),
    endpoint: 'https://api.powerledger.io',
    ethereum: {
      network: 'mainnet' as EthereumNetwork,
      contract: {
        erc20: '0x595832f8fc6bf59c85c527fec3740a1b7a361269',
        stake: '0xba33Aa06901B7662e17869f588b77c04fb0Cd872'
      },
      cooldownHours: 168
    },
    solana: {
      testWallets: false,
      waitBeforeAllowingManualStakeMs: undefined // do not allow user to trigger the solana stake if not auto-triggered in prod
    }
  },
  ropsten: {
    ...ropstenConfig
  }
};

const getStage = (): Stage => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'local';
    case 'test':
      return 'local';
    case 'production':
      return (process.env.REACT_APP_STAGE as Stage) || 'preprod';
    default:
  }

  return 'local';
};

// Log only in development mode (local server) or non-prod deployed server
export const shouldLog = () =>
  process.env.NODE_ENV === 'development' ||
  (process.env.NODE_ENV === 'production' && getStage() !== 'prod');

const config = stageConfig[getStage()];

export default config;
