import { FC, useEffect, useMemo, useState } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { useStake } from '../../lib/state/context';
import { useWeb3React } from '@web3-react/core';
import { Fees } from '../../lib/ethereum/util';
import { calculateUnlockFees } from '../../lib/ethereum/withdraw';
import { FeeView } from '../common/FeeView';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const ContinueWithdrawalConfirm: FC<Props> = ({ ...props }) => {
  const { stake } = useStake();
  const { library } = useWeb3React();
  const [fees, setFees] = useState<Fees>();

  useEffect(() => {
    if (props.open) {
      calculateUnlockFees(stake, library).then(setFees);
    }
  }, [stake?.feeData?.gasPrice?.toNumber(), library, props.open]);

  const message = useMemo(
    () => (
      <>
        <span>A withdrawal is in progress - continue?</span>
        {fees && (
          <>
            <br />
            <FeeView fees={fees} />
          </>
        )}
      </>
    ),
    [fees]
  );

  return <Confirm {...props} message={message} title="Confirm Withdrawal" />;
};
