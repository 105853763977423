import { createAsyncThunk } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from 'ethers';
import { PublicKey } from '@solana/web3.js';
import { EthStake } from '../types';
import { ThunkConfig } from './common';
import * as powrContract from '../../ethereum/powrContract';
import * as stakeContract from '../../ethereum/stakeContract';
import { triggerSolanaStake } from '../actions';
import { pollForSolanaStake } from './pollForSolanaStake';
import { ethStakeDetailsToStatus } from '../../ethereum/util';
import config from '../../config';
import { ZERO } from '../../constants';

export const loadEthStake = createAsyncThunk<
  EthStake,
  Web3Provider,
  ThunkConfig
>('eth/loadStake', async (provider, { getState, dispatch }) => {
  const { ethWallet } = getState().stake;
  if (!ethWallet) return Promise.reject(new Error('No wallet selected'));

  const [approvedAmount, stakeDetails] = await Promise.all([
    powrContract.getApprovedAmount(provider, ethWallet).then(BigNumber.from),
    stakeContract.getStakeDetails(provider, ethWallet)
  ]);

  if (
    stakeDetails.stake.gt(ZERO) &&
    !getState().stake.solanaStake &&
    !!getState().stake.activeProcess
  ) {
    // we have an eth stake but no solana stake yet. Start polling for the solana stake
    dispatch(pollForSolanaStake());

    // After some time, if no stake is registered on solana, indicate to the UI that we can trigger it
    // manually
    const waitForSolanaStakeMS = config.solana.waitBeforeAllowingManualStakeMs;
    if (waitForSolanaStakeMS) {
      setTimeout(() => {
        dispatch(triggerSolanaStake('ENABLED'));
      }, waitForSolanaStakeMS);
    }
  }

  const stakedAmount = stakeDetails.stake;
  const status = ethStakeDetailsToStatus(stakeDetails);

  return {
    approvedAmount,
    stakedAmount,
    status,
    rewards: stakeDetails.stakeRewards,
    ethFee: stakeDetails.ethFee,
    unstakeTimestamp: stakeDetails.unstakeTimestamp,
    registeredStaker: stakeDetails.registeredStaker
      ? new PublicKey(stakeDetails.registeredStaker)
      : undefined,
    registeredStakerValidatorPubKey:
      stakeDetails.registeredStakerValidatorPubKey
        ? new PublicKey(stakeDetails.registeredStakerValidatorPubKey)
        : undefined
  };
});
