import { createAsyncThunk } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import {
  getGlobalStakeParameters,
  GlobalStakeParameters
} from '../../ethereum/stakeContract';

export const loadGlobalStakeParameters = createAsyncThunk<
  GlobalStakeParameters,
  Web3Provider
>('eth/loadGlobalStakeParameters', getGlobalStakeParameters);
