import { FC, useCallback, useEffect, useState } from 'react';
import { Validator } from '../../lib/validator';
import { Button } from '../common/Button';
import { useStake } from '../../lib/state/context';
import { getValidators } from '../../api/staker';

export const TriggerSolanaStake: FC = () => {
  const [validator, setValidator] = useState<Validator>();
  const [loading, setLoading] = useState(false);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const { stake, triggerSolanaStake } = useStake();

  // load the validators from the API
  useEffect(() => {
    setLoading(true);
    if (stake?.ethStake?.registeredStakerValidatorPubKey) {
      (async () => {
        const validators = await getValidators();

        const foundValidator = validators.find((v) =>
          // check for undefined is done above.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          v.votePubkey.equals(stake!.ethStake.registeredStakerValidatorPubKey!)
        );

        setValidator(foundValidator);
      })().finally(() => setLoading(false));
    }
  }, [stake, setLoading, setValidator]);

  const onClick = useCallback(() => {
    if (validator && stake) {
      triggerSolanaStake(stake.ethStake.stakedAmount, validator);
      setHasBeenClicked(true);
    }
  }, [stake, validator, triggerSolanaStake]);

  const element =
    !validator || !stake ? (
      <span className="w-full text-center">
        Validator or Stake missing
        {validator}
      </span>
    ) : (
      <Button
        onClick={onClick}
        disabled={loading || hasBeenClicked}
        className="relative">
        Delegate Stake
      </Button>
    );
  return <div className="grid">{element}</div>;
};
