export function Disclaimer() {
  return (
    <div className="disclamer text-justify font-body disclaimer-text">
      <p>
        <span>
          By using this page and participating in staking on the Powerledger
          Blockchain you are deemed to have accepted the{' '}
          <a href="https://www.powerledger.io/legal/staking-terms">
            Staking Terms and Disclaimers
          </a>{' '}
          and consent to collection of information in accordance with the{' '}
          <a href="https://www.powerledger.io/legal/privacy-policy">
            Power Ledger Privacy Policy.
          </a>{' '}
          If you do not accept these, you are not authorised to stake and to use
          this page or the tools provided.
        </span>
      </p>
      <p className="mt-5">
        <span>
          100% commission validators <span className="underline">DO NOT</span>{' '}
          pay any rewards. Stake with the low commission validators to earn
          rewards.
        </span>
      </p>
    </div>
  );
}
