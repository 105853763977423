import { FC } from 'react';
import * as React from 'react';
import { InfoPopup, ResolvedProps as InfoProps } from './InfoPopup';

export const UndelegateCooldownInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => {
  return (
    <InfoPopup
      open={open}
      setOpen={setOpen}
      {...props}
      message={
        <>
          <p>Your stake is being undelegated on PLChain.</p>
          <p>
            This typically takes one Epoch (approximately 2-3 days). After the
            epoch is complete, you will be able to trigger the unlocking of your
            stake on Ethereum.
          </p>
        </>
      }
      title={'Cooldown'}>
      {children}
    </InfoPopup>
  );
};
