import { PublicKey } from '@solana/web3.js';

export const UNDELEGATE_ACTION = 'UNDELEGATE';

export type UndelegateRequest = {
  solAddress: PublicKey;
  ethAddress: string;
  stakeAccount: PublicKey;
  timestamp: string;
  signature: string;
};
