import { Web3ReactProvider } from '@web3-react/core';
import React, { useMemo } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PageWrapper } from './pages/PageWrapper';
import { StakeProvider } from './lib/state/context';
import { store } from './store';
import config from './lib/config';
import { ConfirmProcessProvider } from './components/common/ConfirmProcessProvider';

const getLibrary = (provider: any) => new Web3Provider(provider);

export const App: React.FC = () => {
  const network = WalletAdapterNetwork.Mainnet;
  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      // include test wallets in non-prod mode
      ...(config.solana.testWallets
        ? [
            getSlopeWallet(),
            getLedgerWallet(),
            getSolletWallet({ network }),
            getSolletExtensionWallet({ network })
          ]
        : [])
    ],
    [network]
  );

  return (
    <Provider store={store}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <StakeProvider>
                <ConfirmProcessProvider>
                  <Toaster />
                  <PageWrapper />
                </ConfirmProcessProvider>
              </StakeProvider>
            </Web3ReactProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Provider>
  );
};
