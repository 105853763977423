import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeeData, Web3Provider } from '@ethersproject/providers';
import { getFeeData } from '../../ethereum/util';

export const REFRESH_INTERVAL_MS = 30_000;

export const loadFeeData = createAsyncThunk<FeeData, Web3Provider>(
  'eth/loadFeeData',
  async (provider, { dispatch }) => {
    // refresh the fee data every 30 seconds
    setTimeout(() => {
      dispatch(loadFeeData(provider));
    }, REFRESH_INTERVAL_MS);

    return getFeeData(provider);
  }
);
