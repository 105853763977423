import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Fees, majorPOWRToMinor } from '../ethereum/util';
import { calculateUnlockFees } from '../ethereum/withdraw';
import { useStake } from '../state/context';
import { StakeState } from '../state/types';
import { useAppDispatch } from '../../store';
import { error } from '../state/actions';

const feesBelowStake = (stake: StakeState, fees: Fees): boolean =>
  stake.ethStake.stakedAmount.gt(majorPOWRToMinor(fees.powr));

export const useUnlockFee = () => {
  const dispatch = useAppDispatch();
  const { stake } = useStake();
  const [fees, setFees] = useState<Fees>();
  const { library, account } = useWeb3React();
  // withdrawal is allowed if the fees are below the stake
  // if no fees or stake is set in state yet, assume withdrawal is allowed
  // as blocking withdrawal should be treated as an edge case
  const [withdrawAllowed, setWithdrawAllowed] = useState(true);

  useEffect(() => {
    if (!account) return;
    calculateUnlockFees(stake, library)
      .then((unlockFees) => {
        setFees(unlockFees);
        if (unlockFees && stake) {
          setWithdrawAllowed(feesBelowStake(stake, unlockFees));
        }
      })
      .catch((e) => dispatch(error(e)));
  }, [stake?.feeData?.gasPrice?.toNumber(), library, account]);

  return { fees, withdrawAllowed };
};
