import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionReceipt } from '@ethersproject/providers';
import * as stakeContract from '../../ethereum/stakeContract';
import {
  getPendingMetaForStakeAction,
  StakeActionPayload,
  StakeActionThunkConfig
} from './common';
import {
  ethTransactionConfirmed,
  ethTransactionBroadcast,
  ethTransactionTooSlow,
  waitingForMetamask
} from '../actions';
import { loadEthStake } from './loadEthStake';
import { waitForTransaction } from '../../ethereum/util';

export const stakeEth = createAsyncThunk<
  TransactionReceipt,
  StakeActionPayload,
  StakeActionThunkConfig
>(
  'eth/stake',
  async ({ amount, provider, validator }, { dispatch, getState }) => {
    const { ethWallet } = getState().stake;
    const solWallet = getState().stake.solanaWallet;

    if (!ethWallet || !solWallet) {
      throw new Error('ethWallet or solWallet not found');
    }

    dispatch(waitingForMetamask(true));
    const response = await stakeContract.stakeTokens(
      provider,
      provider.getSigner(),
      {
        validator,
        amount,
        ethAddress: ethWallet,
        solAddress: solWallet
      }
    );
    dispatch(waitingForMetamask(false));

    dispatch(ethTransactionBroadcast(response.hash));

    // If the Tx is not complete in 90 seconds dispatch ethTransactionTooSlow
    const txTimer = setTimeout(() => {
      dispatch(ethTransactionTooSlow(true));
    }, 90000);

    const receipt = await waitForTransaction(response);

    clearTimeout(txTimer);

    dispatch(ethTransactionConfirmed(response.hash));
    dispatch(loadEthStake(provider));

    return receipt;
  },
  // adds the stake request data to the pending action, so that the reducer can add it to the state
  {
    getPendingMeta: getPendingMetaForStakeAction
  }
);
