import { FC } from 'react';
import * as React from 'react';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';

export const StakeAmountInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => (
  <InfoPopup
    open={open}
    setOpen={setOpen}
    {...props}
    message={
      <>
        <p>Stake amount is the amount of POWR tokens you want to stake.</p>
        <p>Tokens will be locked for the duration of the stake.</p>
        <p>You can only stake tokens that you own.</p>
        <p>You can only stake positive whole numbers of POWR tokens.</p>
      </>
    }
    title={'Stake Amount'}>
    {children}
  </InfoPopup>
);
