import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from './common';
import { SOLANA_POLL_INTERVAL_MS } from '../../constants';
import { loadSolanaStake } from './loadSolanaStake';
import { StakeState } from '../types';
import { showStakingSuccessMessage } from '../actions';

const isSolanaStakeDelegated = (stakeState: StakeState) => {
  const solanaStakeStatus = stakeState.solanaStake?.status;
  return solanaStakeStatus && solanaStakeStatus !== 'NOT_DELEGATED';
};

/**
 * While waiting for the solana stake to be confirmed,
 * poll the backend. Once the solana stake is confirmed or an error occurs,
 * stop the polling
 */
export const pollForSolanaStake = createAsyncThunk<
  void | null,
  void,
  ThunkConfig
>(
  'solana/pollStake',
  (_, { getState, dispatch }) =>
    // return a promise that only resolves when the polling is over
    new Promise<void>((resolve) => {
      // If the stake is registered on eth
      // but not on Solana, poll the backend until the solana endpoint is
      const intervalId = setInterval(() => {
        if (!isSolanaStakeDelegated(getState().stake)) {
          dispatch(loadSolanaStake());
        } else if (intervalId) {
          // the stake is delegated, show success message and stop polling
          dispatch(showStakingSuccessMessage(true));
          clearInterval(intervalId);
          resolve();
        }
      }, SOLANA_POLL_INTERVAL_MS);
    })
);
