import NumberFormat from 'react-number-format';
import { Fees } from '../../lib/ethereum/util';
import { FC } from 'react';
import { InfoDialogIcon } from './InfoDialogIcon';
import { UnlockFeeInfoPopup } from '../modal/UnlockFeeInfoPopup';

type Props = { fees: Fees };
export const FeeView: FC<Props> = ({ fees }) => (
  <div className="inline-flex">
    <span>
      Estimated unlock fee:{' '}
      <NumberFormat
        decimalScale={3}
        value={fees.powr}
        displayType="text"
        suffix=" POWR"
      />
    </span>
    <InfoDialogIcon Popup={UnlockFeeInfoPopup} />
  </div>
);
