import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { getBalance } from '../ethereum/powrContract';
import { ZERO } from '../constants';
import { useStake } from '../state/context';

export const useBalance = (): BigNumber | undefined => {
  const [balance, setBalance] = useState<BigNumber>();
  const { stake } = useStake();

  const { library, account } = useWeb3React();

  useEffect(() => {
    if (account && library) {
      getBalance(library, account)
        .then((userBalance) => {
          setBalance(userBalance);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          setBalance(ZERO);
        });
    }
    // Include the eth stake in the effect so that changes to the stake trigger a recalculation of the POWR balance
  }, [account, library, stake?.ethStake]);

  return balance;
};
