import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { FC, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button } from '../common/Button';
import { SolanaWalletConnectingInfo } from '../modal/SolanaWalletConnectingInfo';

export const SolanaConnector: FC<{ disabled?: boolean }> = ({
  disabled = false
}) => {
  const [
    isShowingWalletConnectionInfoDialog,
    setShowingWalletConnectionInfoDialog
  ] = useState(false);

  const { connected, connecting } = useWallet();

  const ConnectingButton = () => (
    <Button onClick={() => setShowingWalletConnectionInfoDialog(true)}>
      Connecting...
    </Button>
  );

  return (
    <div
      className="inline-flex items-center justify-center"
      data-testid="wallet-solana">
      <SolanaWalletConnectingInfo
        open={isShowingWalletConnectionInfoDialog}
        setOpen={setShowingWalletConnectionInfoDialog}
      />
      {connecting ? (
        <ConnectingButton />
      ) : (
        <WalletMultiButton
          style={{ display: 'flex', flex: '1 1 0' }}
          disabled={disabled}
          startIcon={undefined}
          className="pl-button">
          {connected ? 'Disconnect Powerledger' : 'Connect Powerledger'}
        </WalletMultiButton>
      )}
    </div>
  );
};
