import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import logger from 'redux-logger';
import stakeReducer from './lib/state/slice';
import { shouldLog } from './lib/config';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      // This is to handle things like PublicKeys and BigNumbers in state
      // TODO: If we see issues with the UI updating strangely, or reducers messing up the types
      // of values in the state, this may be why.
      serializableCheck: false
    });
    // Add logging of redux state when in dev mode
    if (shouldLog()) {
      return middlewares.concat(logger);
    }

    return middlewares;
  },
  reducer: {
    stake: stakeReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {stake: StateStake}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
