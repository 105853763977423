import React, { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWeb3React } from '@web3-react/core';
import { Header } from '../components/Header';
import { Accordion } from '../components/Accordion';
import { EthereumConnector } from '../components/wallet/EthereumConnector';
import { SolanaConnector } from '../components/wallet/SolanaConnector';
import { ViewStake } from '../components/view/ViewStake';
import { Footer } from '../components/Footer';
import { Disclaimer } from '../components/Disclaimer';

export const PageWrapper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { connected: solWalletConnected, publicKey } = useWallet();
  const { active: ethWalletConnected } = useWeb3React();

  useEffect(() => {
    if (ethWalletConnected && solWalletConnected && publicKey) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ethWalletConnected,
    solWalletConnected,
    publicKey,
    activeStep,
    setActiveStep
  ]);

  return (
    <div className="h-screen">
      <Header />
      <div className="h-1/2 form-container flex flex-auto flex-col gap-4 text-neutral-900 font-bold">
        <Accordion open={activeStep >= 0}>
          <div className="max-w-2xl mx-auto p-2 grid grid-cols-2 w-full">
            <EthereumConnector />
            <SolanaConnector />
          </div>
        </Accordion>
        <Accordion open={activeStep >= 1}>
          <div className="max-w-2xl bg-gray-200 bg-opacity-20 mx-auto rounded-none p-5">
            <ViewStake />
          </div>
        </Accordion>
        <Disclaimer />
      </div>
      <Footer />
    </div>
  );
};
