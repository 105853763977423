import { BigNumber } from 'ethers';
import { EthereumNetwork } from './config';
import config from './config';

export const ZERO = BigNumber.from(0);

export const ONE_MINUTE_MILLIS = 1000 * 60;
export const ONE_HOUR_MILLIS = ONE_MINUTE_MILLIS * 60;
export const ONE_DAY_MILLIS = ONE_HOUR_MILLIS * 24;

export const COOLDOWN_TIME_MILLIS =
  ONE_HOUR_MILLIS * config.ethereum.cooldownHours;

// Defined by the solana staking program
export const MINIMUM_STAKE_LAMPORTS = 10_000;

// The cost per signature of a solana transaction (not including rent)
export const SOLANA_NETWORK_FEE = 5_000;

// While waiting for the solana stake to be confirmed,
// Poll the backend this often
export const SOLANA_POLL_INTERVAL_MS = 5_000;

export const SOLANA_EPOCH_LENGTH_HOURS = 48;

// The divisor used to calculate the ratio of earned solstice to POWR rewards.
// Must match the documentation for powrRatio in GlobalStakeParameters
export const POWR_DIVISOR = 10_000;

export const WEI_PER_ETH = 1e18;

export const CHAIN_ID: { [key in EthereumNetwork]: string } = {
  mainnet: '0x1',
  ropsten: '0x3',
  rinkeby: '0x4',
  goerli: '0x5'
};
