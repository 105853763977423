import { FC } from 'react';
import * as React from 'react';
import { InfoPopup, ResolvedProps as InfoProps } from './InfoPopup';

export const UnlockCooldownInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => {
  return (
    <InfoPopup
      open={open}
      setOpen={setOpen}
      {...props}
      message={
        <>
          <p>Your stake is in Cooldown state.</p>
          <p>
            You can withdraw your POWR tokens seven days after triggering
            Undelegate.
          </p>
        </>
      }
      title={'Cooldown'}>
      {children}
    </InfoPopup>
  );
};
