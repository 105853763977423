import React, { FC, useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { ResolvedProps as InfoProps } from '../modal/InfoPopup';

type Props = {
  Popup: FC<InfoProps>;
};
export const InfoDialogIcon: FC<Props> = ({ Popup }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="inline-flex items-center" onClick={() => setOpen(true)}>
      <Popup setOpen={setOpen} open={isOpen} />
      <QuestionMarkCircleIcon
        className="h-5 w-5 ml-2 cursor-pointer text-gray-400"
        aria-hidden="true"
        onClick={() => setOpen(true)}
      />
    </div>
  );
};
