import { Disclosure, Transition } from '@headlessui/react';
import { FC } from 'react';

type Props = { open: boolean };
export const Accordion: FC<Props> = ({ open, children }) => (
  <Disclosure defaultOpen={open}>
    <Transition
      show={open}
      enter="transition duration-500 ease-out"
      enterFrom="transform scale-y-0 opacity-0"
      enterTo="transform scale-y-100 opacity-100 origin-top"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-y-100 opacity-100"
      leaveTo="transform scale-y-0 opacity-0">
      <Disclosure.Panel static as="dd">
        {children}
      </Disclosure.Panel>
    </Transition>
  </Disclosure>
);
