import {
  createSlice,
  Draft,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction
} from '@reduxjs/toolkit';
import { PublicKey } from '@solana/web3.js';
import { toast } from 'react-hot-toast';
import { BigNumber } from '@ethersproject/bignumber';
import { AnyAsyncThunk } from '@reduxjs/toolkit/src/matchers';
import {
  initialStakeState,
  SolanaStakeTrigger,
  StakeResponse,
  StakeState,
  StakeStatus
} from './types';
import { loadSolanaStake } from './thunks/loadSolanaStake';
import { loadEthStake } from './thunks/loadEthStake';
import { stakeEth } from './thunks/stakeEth';
import { approveDeposit } from './thunks/approveDeposit';
import { stakeSol } from './thunks/stakeSol';
import { withdrawPLChain } from './thunks/withdrawPLChain';
import { undelegate } from './thunks/undelegate';
import { normaliseError } from './thunks/common';
import { pollForSolanaStake } from './thunks/pollForSolanaStake';
import {
  allowSolanaDelegateButton,
  calculateStatus,
  walletMismatch
} from './util';
import { WalletMismatchError, WrongNetworkError } from '../error';
import { omit } from 'ramda';
import { withdrawEth } from './thunks/withdrawEth';
import { loadGlobalStakeParameters } from './thunks/loadGlobalStakeParameters';
import { loadFeeData } from './thunks/loadFeeData';
import { UnknownAsyncThunkRejectedAction } from '@reduxjs/toolkit/dist/matchers';
import {
  isNetworkChangedEthersError,
  isWrongNetworkEthersError
} from '../ethereum/errors';
import { loadEpochInfo } from './thunks/loadEpochInfo';
import { unlock } from './thunks/unlock';

// an action matcher that matches all actions
const matchAll = () => true;

const asyncActions: [AnyAsyncThunk, ...AnyAsyncThunk[]] = [
  loadSolanaStake,
  loadEthStake,
  approveDeposit,
  stakeEth,
  stakeSol,
  withdrawPLChain,
  undelegate,
  unlock,
  pollForSolanaStake
];

const isAPendingAction = isPending(...asyncActions);
const isAFulfilledAction = isFulfilled(...asyncActions);
const isARejectedAction = isRejected(...asyncActions);

const pendingStakeEthTransaction = isAnyOf(
  approveDeposit.pending,
  stakeEth.pending
);

const pendingWithdrawEthTransaction = isAnyOf(withdrawEth.pending);

const processComplete = isAnyOf(withdrawEth.fulfilled);

// Handler for rejected ethereum actions, allowing us to display non-generic errors
// eg when the wrong network is selected
const isARejectedEthAction = isAnyOf(
  approveDeposit.rejected,
  stakeEth.rejected,
  withdrawEth.rejected,
  loadFeeData.rejected,
  loadGlobalStakeParameters.rejected,
  loadEthStake.rejected
);

// Handler for rejected actions that do not fall into any other handled category
// The type guard allows this to be used efficiently in the reducer
const isAnUncaughtRejectedAction = (
  action: any
): action is UnknownAsyncThunkRejectedAction =>
  !isARejectedEthAction(action) && isARejectedAction(action);

// Update the state with an error message
// Note - this mutates the state object, but when used inside a slice,
// the mutation is converted to a new state object
const handleErrorActionResult = (
  state: Draft<StakeState>,
  { payload, error }: { payload: any; error: any }
): void => {
  const errorFromAction = payload || error;
  // this is a side effect, but it is convenient to trigger it here
  toast.error(normaliseError(errorFromAction));
  // eslint-disable-next-line no-console
  console.error(error);
  state.error = errorFromAction;
};

// update the stake with details from an updated solana stake
const handleUpdateSolStake = (
  state: StakeState,
  solStake: StakeResponse | null
) => {
  state.solanaStake = solStake
    ? {
        ...state.solanaStake,
        ...solStake
      }
    : undefined;

  // If the user returns to the page, and they have a stake on the eth side, but the solana side is not
  // yet staked, they can trigger the sol stake.
  if (
    state.ethStake.status === 'DELEGATED' && // eth stake exists
    !state.solanaStake && // no solana stake
    !state.activeProcess && // we are not in the middle of a process (i.e. we have just arrived)
    // we have not disabled the manual delegate button
    allowSolanaDelegateButton()
  ) {
    state.solanaStakeTrigger = 'ENABLED';
  }

  if (state.solanaStake) {
    state.solanaStakeTrigger = 'N/A';
  }

  if (state.ethStake && !state.solanaStake) {
    if (state.ethStake.status === 'COOLDOWN_ETHEREUM') {
      // The solana stake is withdrawn, the eth stake is in cooldown - once complete the state will be Unlocked
    }
  }

  if (walletMismatch(state)) {
    state.error = new WalletMismatchError();
  }
};

export const stakeSlice = createSlice({
  name: 'stake',
  initialState: initialStakeState,
  reducers: {
    setWallets: (
      state,
      action: PayloadAction<{ solanaWallet: PublicKey; ethWallet: string }>
    ) => {
      state.solanaWallet = action.payload.solanaWallet;
      state.ethWallet = action.payload.ethWallet;
      // clear the rest of the state so we don't have stale data
      state.solanaStake = initialStakeState.solanaStake;
      state.ethStake = initialStakeState.ethStake;
      state.activeProcess = initialStakeState.activeProcess;
      state.error = initialStakeState.error;
      state.solanaStakeTrigger = initialStakeState.solanaStakeTrigger;
      state.loading = initialStakeState.loading;
      state.status = 'UNKNOWN';
    },
    error: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
      // this is a side effect, but it is convenient to trigger it here
      toast.error(normaliseError(action.payload));
    },
    updateEthStake: (
      state,
      action: PayloadAction<{ stakedAmount: BigNumber; status: StakeStatus }>
    ) => {
      state.ethStake.stakedAmount = action.payload.stakedAmount;
      state.ethStake.status = action.payload.status;
    },
    ethTransactionBroadcast: (state, action: PayloadAction<string>) => {
      if (state.activeProcess) {
        state.activeProcess.pendingTransaction = action.payload;
      }
    },
    ethTransactionTooSlow: (state, action: PayloadAction<boolean>) => {
      if (state.activeProcess) {
        state.activeProcess.showSpeedup = action.payload;
      }
    },
    ethTransactionConfirmed: (state, action: PayloadAction<string>) => {
      if (state.activeProcess?.pendingTransaction === action.payload) {
        state.activeProcess.pendingTransaction = undefined;
        state.activeProcess.showSpeedup = false;
      } else throw new Error('Eth transaction confirmed but was not pending');
    },
    triggerSolanaStake: (state, action: PayloadAction<SolanaStakeTrigger>) => {
      state.solanaStakeTrigger = action.payload;
    },
    showStakingSuccessMessage: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.showStakingSuccessMessage = action.payload || false;
    },
    waitingForMetamask: (state, action: PayloadAction<boolean>) => {
      state.waitingForMetamask = action.payload;
    },
    confirmCompletedProcess: (state) => {
      state.activeProcess = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadEthStake.fulfilled, (state, { payload: ethStake }) => {
      state.ethStake = {
        ...state.ethStake,
        ...(ethStake || {})
      };

      if (walletMismatch(state)) {
        state.error = new WalletMismatchError();
      }
    });

    builder.addCase(
      loadGlobalStakeParameters.fulfilled,
      (state, { payload }) => {
        state.globalStakeParameters = payload;
      }
    );

    builder.addCase(loadFeeData.fulfilled, (state, { payload }) => {
      state.feeData = payload;
    });

    builder.addCase(loadEpochInfo.fulfilled, (state, { payload }) => {
      state.epochInfo = payload;
    });

    builder.addCase(stakeSol.pending, (state, {}) => {
      state.solanaStakeTrigger = 'IN PROGRESS';
    });

    builder.addCase(pollForSolanaStake.fulfilled, (state) => {
      state.activeProcess = undefined;
    });

    builder.addCase(
      loadSolanaStake.fulfilled,
      (state, { payload: solStake }) => {
        handleUpdateSolStake(state, solStake);
      }
    );

    builder.addCase(undelegate.pending, (state) => {
      // Create the process, but do not set userConfirm yet, as that will trigger the confirm dialog
      state.activeProcess = {
        type: 'UNDELEGATE',
        request: {}
      };
    });

    builder.addCase(
      undelegate.fulfilled,
      (state, { payload: { solStake } }) => {
        if (solStake) {
          handleUpdateSolStake(state, solStake);
        }

        if (state.activeProcess) state.activeProcess.userConfirm = true;
      }
    );

    builder.addCase(unlock.pending, (state, { meta }) => {
      state.activeProcess = {
        type: 'UNLOCK',
        request: omit(['arg', 'requestId', 'requestStatus'], meta)
      };
    });

    builder.addCase(
      unlock.fulfilled,
      (state, { payload: { solStake, transactionReceipt } }) => {
        handleUpdateSolStake(state, solStake);

        if (state.activeProcess) state.activeProcess.userConfirm = true;

        state.ethStake.unstakeTransaction = transactionReceipt;
      }
    );

    builder.addMatcher(pendingStakeEthTransaction, (state, { meta }) => {
      state.activeProcess = {
        type: 'STAKE',
        request: omit(['arg', 'requestId', 'requestStatus'], meta)
      };
    });

    builder.addMatcher(pendingWithdrawEthTransaction, (state, { meta }) => {
      state.activeProcess = {
        type: 'WITHDRAW',
        request: omit(['arg', 'requestId', 'requestStatus'], meta)
      };
    });

    builder.addMatcher(processComplete, (state) => {
      state.activeProcess = undefined;
    });

    builder.addMatcher(isAPendingAction, (state) => {
      state.loading += 1;
    });

    builder.addMatcher(
      isAnyOf(isAFulfilledAction, isARejectedAction),
      (state) => {
        state.loading -= 1;
      }
    );

    builder.addMatcher(isARejectedEthAction, (state, action) => {
      if (isWrongNetworkEthersError(action.error)) {
        state.error = new WrongNetworkError();
      } else if (isNetworkChangedEthersError(action.error)) {
        // We can ignore this. useEffect will pick it up and update the state.
        console.log('Network changed error', action.error);
      } else handleErrorActionResult(state, action);
    });

    builder.addMatcher(isAnUncaughtRejectedAction, handleErrorActionResult);

    builder.addMatcher(matchAll, (state) => {
      state.status = calculateStatus(state);
    });
  }
});

// Action creators are generated for each case reducer function
export const { setWallets, updateEthStake, triggerSolanaStake } =
  stakeSlice.actions;

export default stakeSlice.reducer;
