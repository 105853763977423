import { FC } from 'react';
import * as React from 'react';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';

export const UnlockFeeInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => (
  <InfoPopup
    open={open}
    setOpen={setOpen}
    {...props}
    message={
      <>
        <p>
          The unlock fee is the gas charge incurred when unlocking your stake.
        </p>
        <br />
        <p>
          It is charged to your stake balance in POWR on withdrawal, according
          to the gas price and POWR exchange rate at time of withdrawal.
        </p>
        <br />
        <p className="italic font-semibold">
          If the unlock fee exceeds the total stake - the withdraw transaction
          will fail.
        </p>
      </>
    }
    title={'Unlock Fee'}>
    {children}
  </InfoPopup>
);
