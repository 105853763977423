import React, { FC } from 'react';
import { classNames } from '../../lib/util';

type Props = {
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};
type ButtonProps = Props &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
export const Button: FC<ButtonProps> = ({
  disabled = false,
  onClick,
  children,
  className = '',
  ...props
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={classNames(
      'pl-button',
      'disabled:cursor-not-allowed',
      className
    )}
    {...props}>
    {children}
  </button>
);
