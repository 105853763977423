import { FC } from 'react';

type Props = { width?: number; height?: number; className?: string };
export const LoadingImage: FC<Props> = (props) => (
  <img
    src="https://storage.googleapis.com/staker-ux-frontend/Powerledger-loading.gif"
    {...props}
    alt="Loading"
  />
);
