import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../lib/util';
import { useWeb3React } from '@web3-react/core';
import { EthereumNetwork } from '../../lib/config';

const nameMap: Record<string, EthereumNetwork> = {
  // Ethers reports mainnet's chain as homestead, which is not meaningful to most users.
  homestead: 'mainnet'
};

/**
 * Shows the current Ethereum network the user's wallet is connected to
 */
export const NetworkIndicator: FC = ({ ...props }) => {
  const { library } = useWeb3React();
  const [network, setNetwork] = useState<EthereumNetwork>();

  // convert the network name according to nameMap
  const updateNetwork = useCallback(
    (rawNetwork: string | undefined) => {
      const convertedNetwork: EthereumNetwork | undefined = (
        rawNetwork ? nameMap[rawNetwork] || rawNetwork : rawNetwork
      ) as EthereumNetwork | undefined;
      setNetwork(convertedNetwork);
    },
    [setNetwork]
  );

  // set the network name whenever the library (aka web3 provider) changes
  useEffect(() => {
    if (!library) {
      updateNetwork(undefined);
    } else {
      library.getNetwork().then(({ name }: { name?: string }) => {
        updateNetwork(name);
      });
    }
  }, [library, updateNetwork]);

  const shouldShowNetwork = useMemo(
    () => !!network && network !== 'mainnet',
    [network]
  );

  return shouldShowNetwork ? (
    <div className={classNames('network-indicator mt-2')} {...props}>
      {network}
    </div>
  ) : (
    <></>
  );
};
