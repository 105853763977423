import { FC } from 'react';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';

export const WarmupInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => {
  return (
    <InfoPopup
      open={open}
      setOpen={setOpen}
      {...props}
      message={
        <>
          <p>Your stake is in Warmup state.</p>
          <p>
            Stakes in Warmup will become active and begin earning rewards in the
            next epoch.
          </p>
        </>
      }
      title={'Warmup'}>
      {children}
    </InfoPopup>
  );
};
