import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from './common';
import * as api from '../../../api/staker';
import { UnlockRequest, UnlockResponse } from '../../withdraw';

export const withdrawPLChain = createAsyncThunk<
  UnlockResponse,
  UnlockRequest,
  ThunkConfig
>('sol/withdraw', async (unlockRequest) => {
  if (unlockRequest.stake) {
    return api.unlock(unlockRequest as Required<UnlockRequest>);
  } else {
    // no sol stake to withdraw
    throw new Error('No sol stake to withdraw');
  }
});
