import { FC, useMemo } from 'react';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';
import { useStake } from '../../lib/state/context';
import { calculateEpochProgressPercent } from '../../lib/util';

export const EpochInfoPopup: FC<InfoProps> = ({
  open,
  setOpen,
  children,
  ...props
}) => {
  const { stake } = useStake();

  const epochProgress = useMemo(() => {
    if (!stake?.epochInfo) return 'Calculating...';

    return calculateEpochProgressPercent(stake.epochInfo);
  }, [stake?.epochInfo]);

  const message = useMemo(() => {
    switch (stake?.solanaStake?.status) {
      case 'WARMUP':
        return 'Your stake has been deposited, and will start earning rewards after the warmup period.';
      case 'COOLDOWN_PLCHAIN':
        return 'Your stake is in cooldown, and is no longer earning full rewards.';
      case 'DELEGATED':
        return 'Your stake is earning rewards.';
      default:
        return '';
    }
  }, [stake?.solanaStake?.status]);

  return (
    <InfoPopup
      open={open}
      setOpen={setOpen}
      {...props}
      message={
        <>
          <p>{message}</p>
          <p>
            Staking rewards are paid per <i>Epoch.</i>, which lasts 2-3 days.
          </p>
          <div data-testid="epoch-progress">
            Current Epoch progress: {epochProgress}
          </div>
        </>
      }
      title={'Rewards'}>
      {children}
    </InfoPopup>
  );
};
