import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionReceipt } from '@ethersproject/providers';
import * as stakeContract from '../../ethereum/stakeContract';
import { StakeActionThunkConfig } from './common';
import {
  ethTransactionConfirmed,
  ethTransactionBroadcast,
  ethTransactionTooSlow,
  waitingForMetamask
} from '../actions';
import { loadEthStake } from './loadEthStake';
import { waitForTransaction } from '../../ethereum/util';
import { WithdrawEthRequest } from '../../withdraw';
import { loadSolanaStake } from './loadSolanaStake';

export const withdrawEth = createAsyncThunk<
  TransactionReceipt,
  WithdrawEthRequest,
  StakeActionThunkConfig
>('eth/withdraw', async (withdrawRequest, { dispatch }) => {
  const { provider } = withdrawRequest;

  dispatch(waitingForMetamask(true));
  const response = await stakeContract.withdrawStake(
    provider,
    provider.getSigner()
  );
  dispatch(waitingForMetamask(false));

  dispatch(ethTransactionBroadcast(response.hash));

  // If the Tx is not complete in 90 seconds dispatch ethTransactionTooSlow
  const txTimer = setTimeout(() => {
    dispatch(ethTransactionTooSlow(true));
  }, 90000);

  const receipt = await waitForTransaction(response);

  clearTimeout(txTimer);

  dispatch(ethTransactionConfirmed(response.hash));
  await dispatch(loadEthStake(provider));
  dispatch(loadSolanaStake());

  return receipt;
});
