import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { ReactComponent as MetamaskFox } from '../../assets/images/metamask-fox.svg';
import * as React from 'react';

const MetamaskIcon = () => (
  <div className="mx-auto flex items-center justify-center h-12 w-12">
    <MetamaskFox className="h-12 w-12" />
  </div>
);

type Props = Omit<
  ConfirmProps,
  'okText' | 'cancelText' | 'message' | 'title' | 'onOK'
>;
export const InstallWalletConfirm: FC<Props> = ({ ...props }) => (
  <Confirm
    {...props}
    message={`We were unable to detect a MetaMask wallet browser extension.
    Please install it to continue.`}
    title="Install MetaMask"
    okText="Take me to MetaMask"
    onOK={() => {
      window.open('https://metamask.io/');
    }}
    Icon={<MetamaskIcon />}
  />
);
