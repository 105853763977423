/**
 * Thunk to send a solstice stake request to the backend.
 * Used only to trigger the back-end in the case where it failed to respond to the event triggered by Eventeum
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from './common';
import * as api from '../../../api/staker';
import { loadSolanaStake } from './loadSolanaStake';
import { StakeRequest, StakeResponse, StakeState } from '../types';
import { Validator } from '../../validator';

// if the stake is already registered on ethereum, we can find the validator information from the
// on-chain data
const extractValidatorFromState = (
  state: StakeState
): Validator | undefined => {
  const {
    ethStake: { registeredStaker, registeredStakerValidatorPubKey } = {}
  } = state;
  if (!registeredStakerValidatorPubKey || !registeredStaker) {
    return undefined;
  }

  return {
    address: registeredStaker,
    votePubkey: registeredStakerValidatorPubKey,
    // this is incorrect, but we don't have the information at this point
    // commission is required on the view only, so it being incorrect is not an issue here.
    commission: 0
  };
};

// given tokens already registered on ethereum, create the request that should
// be sent to the backend to register on PLChain
const extractRequestFromState = (
  state: StakeState
): StakeRequest | undefined => {
  // if there is a request in the state, then just return it.
  if (state.activeProcess?.request && state.activeProcess?.type === 'STAKE') {
    return state.activeProcess.request as StakeRequest;
  }

  // if not, the tokens are already staked on Ethereum, we need to trigger the solana stake.
  // Extract the request from the stake record on ethereum.
  const validator = extractValidatorFromState(state);

  if (!validator || !state.ethWallet || !state.solanaWallet) {
    // There is no information on ethereum about the validator, so we cannot trigger the solstice stake
    // Or the wallets are not yet registered, in which case we should not have triggered this thunk.
    return undefined;
  }

  return {
    amount: state.ethStake.stakedAmount,
    ethAddress: state.ethWallet,
    solAddress: state.solanaWallet,
    ethStakeTransaction: state.ethStake.ethStakeTransaction,
    validator
  };
};

export const stakeSol = createAsyncThunk<StakeResponse, void, ThunkConfig>(
  'sol/stake',
  async (_, { dispatch, getState }) => {
    const request = extractRequestFromState(getState().stake);

    if (!request) {
      throw new Error('Stake request details not found');
    }

    const response = await api.stake(request).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    });

    dispatch(loadSolanaStake()).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    });

    return response;
  }
);
