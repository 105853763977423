import { SolanaStakeStatus, StakeStatus } from '../state/types';

export const solToStakeStatus = (solStatus: SolanaStakeStatus): StakeStatus => {
  switch (solStatus) {
    case 'UNINITIALIZED':
      return 'NOT_DELEGATED';
    case 'NOT_DELEGATED':
      return 'NOT_DELEGATED';
    case 'WARMUP':
      return 'WARMUP';
    case 'COOLDOWN':
      return 'COOLDOWN_PLCHAIN';
    case 'DELEGATED':
      return 'DELEGATED';
  }
};
