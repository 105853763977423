import { FC, useCallback } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { normaliseError } from '../../lib/state/thunks/common';
import { StakeState } from '../../lib/state/types';
import {
  extractMessageFromWeb3Error,
  switchNetwork
} from '../../lib/ethereum/util';
import { WalletMismatchError, WrongNetworkError } from '../../lib/error';
import config from '../../lib/config';
import { useStake } from '../../lib/state/context';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Button } from '../common/Button';
import { ZERO } from '../../lib/constants';

const DefaultStakeErrorView: FC<{ error: StakeState['error'] }> = ({
  error
}) => (
  <div className="grid pb-1">
    <ExclamationIcon
      className="mx-auto h-20 w-20 text-red-700"
      aria-hidden="true"
    />
    <h3 className="mx-auto mb-2" data-testid="info-title">
      {' '}
      An error occurred during Staking. Your funds are safe.
    </h3>
    <span className="mx-auto">
      Please wait a few minutes, refresh the page, and try again.
    </span>
    <span className="mx-auto">
      If the issue persists, please contact Powerledger with the following error
      code:
    </span>
    <span data-testid="error-code" className="mt-10 font-mono text-gray-800">
      {extractMessageFromWeb3Error(normaliseError(error))}
    </span>
  </div>
);

const WalletMismatchErrorView: FC<{
  error: WalletMismatchError;
  stake: StakeState;
}> = ({ stake }) => {
  const ethWalletExists = stake.ethStake.stakedAmount?.gt(ZERO);
  return (
    <div className="grid pb-1">
      <ExclamationIcon
        className="mx-auto h-20 w-20 text-red-700"
        aria-hidden="true"
      />
      <h3 className="mx-auto mb-2">Wallet Mismatch</h3>
      <span className="mx-auto">
        {ethWalletExists
          ? 'You have staked POWR using a different Powerledger wallet:'
          : 'You have staked POWR using a different Ethereum wallet.'}
      </span>
      {ethWalletExists && (
        <span className="mx-auto font-mono mt-3 mb-3">
          {stake.ethStake.registeredStaker?.toBase58()}.
        </span>
      )}
      <span className="mx-auto">
        {ethWalletExists
          ? 'Please connect this Powerledger wallet to manage your stake.'
          : 'Please connect the original Ethereum wallet you used to stake.'}
      </span>
    </div>
  );
};

const WrongNetworkErrorView: FC<{
  provider: Web3Provider;
}> = ({ provider }) => {
  const requestSwitchNetwork = useCallback(
    () => switchNetwork(provider, config.ethereum.network),
    [provider]
  );
  return (
    <div className="grid pb-1">
      <ExclamationIcon
        className="mx-auto h-20 w-20 text-red-700"
        aria-hidden="true"
      />
      <h3 className="mx-auto mb-2">Unsupported Network Selected</h3>
      <Button onClick={requestSwitchNetwork} className="mx-auto">
        Switch to {config.ethereum.network}
      </Button>
    </div>
  );
};

export const StakeError: FC = () => {
  const { stake } = useStake();
  const { library } = useWeb3React();

  if (!stake) return null;

  const { error } = stake;

  if (error instanceof WalletMismatchError)
    return <WalletMismatchErrorView error={error} stake={stake} />;

  if (error instanceof WrongNetworkError)
    return <WrongNetworkErrorView provider={library} />;

  return <DefaultStakeErrorView error={error} />;
};
