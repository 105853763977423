import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const InitStakeConfirm: FC<Props> = ({ ...props }) => (
  <Confirm
    {...props}
    message={`Please confirm your staking details before continuing.
Continuing will stake your funds with a minimum lock-up period of seven days.`}
    title="Confirm details"
  />
);
