import { SerializedError } from '@reduxjs/toolkit';
import { fromPairs } from 'ramda';

const safeParse = (x: string): any => {
  try {
    return JSON.parse(x);
  } catch (e) {
    return x;
  }
};

/**
 * Parse ethers error message strings which look like this:
 * "Message (key=value, key=value)"
 * where "value" is json string (aka, a string, number, boolean or object)
 * @param error
 */
export const parseEthersErrorMessage = (
  error: SerializedError
): Record<string, any> =>
  // a "write-once, read-never" function for parsing ethers errors
  // there appears to be no parser library for these, and they have a few strange properties:
  // (see the tests for examples)
  // 1. The data is inside a message string with key=value pairs inside brackets, separated by commas
  // 2. The values are json strings (including strings, numbers, objects and null)
  // 3. The values therefore also include commas.
  fromPairs(
    ((error.message || '').match(/\((.*)\)/) || ['', ''])[1]
      // split the string along all commas (whether they exist in the top-level, or in the json values)
      .split(',')
      .filter((x) => x.length > 0)
      // detect if the string segment is a top-level key=value pair or part of a value string.
      // this can be tricked, if the value contains an "=" sign,
      // but so far no examples have been found in the wild.
      // if the segment is identified as part of a value string,
      // concatenate it to the previous segment, re-adding the comma.
      .reduce(
        (acc: string[], next: string) =>
          next.includes('=')
            ? [...acc, next]
            : [...acc.slice(0, -1), acc.slice(-1) + ',' + next],
        []
      )
      // remove whitespace
      .map((x) => x.trim())
      // now we have a sanitised array of key=value strings,
      // split across the = sign, resulting in an array of pairs
      .map((x) => x.split('='))
      // parse the value into a json object. Note, although the value should be JSON,
      // it is sometimes an unquoted string.
      // Hence, we use safeParse here to avoid errors.
      .map(([k, v]) => [k, safeParse(v)] as [string, any])
  );

/**
 * Detects the error returned by Web3 when the user has chosen the wrong network.
 * This is not fool-proof, because the error may be returned in other cases, but it
 * avoids an async call to ethers getCode(), so can be used in the reducer
 * @param error
 */
export const isWrongNetworkEthersError = (error: SerializedError): boolean =>
  error.code === 'CALL_EXCEPTION' &&
  parseEthersErrorMessage(error).reason === null;

export const isNetworkChangedEthersError = (error: SerializedError): boolean =>
  error.code === 'NETWORK_ERROR' &&
  parseEthersErrorMessage(error).event === 'changed';
