import {
  amountMismatchException,
  StakeException,
  StakeState,
  StakeStatus,
  StakeSummary
} from './types';
import { COOLDOWN_TIME_MILLIS, POWR_DIVISOR, ZERO } from '../constants';
import { lamportsToMinorPowr } from '../ethereum/util';
import { BigNumber } from 'ethers';
import config from '../config';
import { ethereumStakeUndelegated } from '../util';
import { Validator } from '../validator';
import { PublicKey } from '@solana/web3.js';

export enum StakeStep {
  APPROVING_STAKE_REQUEST,
  STAKING,
  WAITING_FOR_PLCHAIN
}

export enum UnlockStep {
  WITHDRAWING_PLCHAIN,
  UNLOCKING_ETH
}

export enum WithdrawStep {
  WITHDRAWING_ETH
}

/**
 * Given the current stake state, derive which step is currently being waited for
 */
// TODO we may want to make this more explicit in the state rather than having to derive it here.
export const getAwaitingStakeStep = (
  stake: StakeState | undefined,
  stakedOnEthereumOnly: boolean
): StakeStep | undefined => {
  if (!stake?.activeProcess && !stake?.solanaStakeTrigger) return undefined;

  const waitingForTransaction = stake?.activeProcess?.pendingTransaction;

  if (waitingForTransaction && stake.ethStake.approvedAmount.eq(ZERO))
    return StakeStep.APPROVING_STAKE_REQUEST;
  if (waitingForTransaction && stake.ethStake.stakedAmount.eq(ZERO))
    return StakeStep.STAKING;

  if (stakedOnEthereumOnly) return StakeStep.WAITING_FOR_PLCHAIN;
  return undefined;
};

/**
 * Given the current stake state, derive which withdrawal step is currently being waited for
 */
// TODO we may want to make this more explicit in the state rather than having to derive it here.
export const getAwaitingWithdrawalStep = (
  stake: StakeState | undefined
): WithdrawStep | undefined => {
  if (!stake?.activeProcess) return undefined;

  const waitingForTransaction = stake?.activeProcess?.pendingTransaction;

  if (!waitingForTransaction) return undefined;

  return WithdrawStep.WITHDRAWING_ETH;
};

export const getAwaitingUnlockStep = (
  stake: StakeState | undefined
): UnlockStep | undefined => {
  if (!stake?.activeProcess) return undefined;

  const inUnlock = stake?.activeProcess.type === 'UNLOCK';
  const solStakeExists = !!stake?.solanaStake;

  if (inUnlock && solStakeExists) {
    return UnlockStep.WITHDRAWING_PLCHAIN;
  }

  // UnstakeTimestamp means unlock is done.
  if (!!stake.ethStake.unstakeTimestamp) return undefined;

  return UnlockStep.UNLOCKING_ETH;
};

export const walletMismatch = (stake: StakeState): boolean => {
  const connectedSolanaWallet = stake.solanaWallet;
  const solanaWalletRegisteredOnStake = stake.ethStake.registeredStaker;
  const ethStakeExists = !!stake.ethStake.registeredStaker;
  const solStakeExists = !!stake.solanaStake;
  const loadingEthStake = stake.ethStake.status === 'UNKNOWN';

  // true if both sol and eth stakes exist but the wallets don't match
  const solAndEthMismatch =
    !!connectedSolanaWallet &&
    !!solanaWalletRegisteredOnStake &&
    ethStakeExists &&
    !connectedSolanaWallet.equals(solanaWalletRegisteredOnStake);

  // true if only a sol stake exists. In this case, the likelihood is that the user has connected the wrong eth
  // wallet.
  const solStakeExistsButEthDoesNot =
    !!connectedSolanaWallet &&
    !loadingEthStake &&
    solStakeExists &&
    !ethStakeExists;

  return solAndEthMismatch || solStakeExistsButEthDoesNot;
};

const amountMismatch = (stake: StakeState): boolean =>
  // TODO this only checks amount mismatches
  // if the stake is warming up.
  // after that, once rewards are added, it is no longer accurate.
  // We can fix this by looking up the stake history on the solana chain,
  // but finding the original balance on solana may be expensive,
  // as it is not stored directly on chain.
  stake.solanaStake?.status === 'WARMUP' &&
  !!stake.solanaStake?.stakedAmount &&
  !stake.ethStake.stakedAmount.eq(
    lamportsToMinorPowr(stake.solanaStake.totalAccountAmount)
  );

const detectExceptionState = (stake: StakeState): StakeException | null => {
  if (
    amountMismatch(stake) &&
    stake.solanaStake?.status !== 'COOLDOWN_PLCHAIN'
  ) {
    return amountMismatchException;
  }

  return null;
};

const calculateRewards = (stake: StakeState): BigNumber => {
  // If the rewards have already been assigned to the stake on ethereum,
  // no need to check the solanaStake
  if (ethereumStakeUndelegated(stake) && stake.ethStake.rewards)
    return stake.ethStake.rewards;

  if (
    !stake.solanaStake?.totalAccountAmount ||
    !stake?.globalStakeParameters?.powrRatio
  )
    return ZERO;

  const balanceDifferenceInMinorPowr = lamportsToMinorPowr(
    stake.solanaStake?.totalAccountAmount
  ).sub(stake.ethStake.stakedAmount);

  return balanceDifferenceInMinorPowr
    .mul(stake.globalStakeParameters.powrRatio)
    .div(BigNumber.from(POWR_DIVISOR));
};

const stakeCooldownComplete = (stake: StakeState): boolean =>
  !!stake.ethStake.unstakeTimestamp &&
  stake.ethStake.unstakeTimestamp * 1000 + COOLDOWN_TIME_MILLIS < Date.now();

/**
 * Determine the status of the stake by inspecting the solana and ethereum stakes
 * @param stake
 */
export const calculateStatus = (stake: StakeState): StakeStatus => {
  switch (stake.solanaStake?.status) {
    case 'WARMUP': // the stake is warming up on PLChain and is locked on ethereum
    case 'DELEGATED': // the stake is delegated on both chains and earning rewards on PL Chain
      return stake.solanaStake?.status;
    case 'COOLDOWN_PLCHAIN':
      // TODO move this logic into the ethstake creation function
      // If the solana stake and the ethereum stake are cooldown but the cooldown has completed, then we can withdraw
      // This is a safeguard in case the solana state has not been updated - in reality the solana cooldown should be
      // faster than the ethereum one, so this case should not happen unless the user leaves the page open for several days
      if (
        stake.ethStake.status === 'COOLDOWN_ETHEREUM' &&
        stakeCooldownComplete(stake)
      ) {
        return 'NOT_DELEGATED';
      }
      return 'COOLDOWN_PLCHAIN';
    case 'NOT_DELEGATED':
      // if the solana stake is NOT_DELEGATED, the status depends on whether the eth stake has been unlocked/withdrawn
      switch (stake.ethStake.status) {
        case 'COOLDOWN_ETHEREUM': // unlock in progress
        case 'UNLOCKED': // unlocked but not withdrawn
        case 'WITHDRAWN':
          return stake.ethStake.status;
        default:
          // unlock not yet started
          return 'NOT_DELEGATED';
      }
    default:
      // the above cases should be exhaustive - the plchain stake status cannot be anything else.
      // This case is added purely as a safety measure.
      return stake.ethStake.status || 'UNKNOWN';
  }
};

const findValidatorByVotePubkey = (
  validators: Validator[],
  votePubkey: PublicKey
) => validators.find((validator) => validator.votePubkey.equals(votePubkey));

// Produce a UI-ready summary of the stake state.
// Note - business logic beyond deriving amounts should happen inside the state reducers instead of here.
export const toStakeSummary = (
  stake: StakeState,
  validators: Validator[]
): StakeSummary => {
  const rewards = calculateRewards(stake);
  const exception = detectExceptionState(stake);

  const validator =
    stake.solanaStake?.validator ||
    (stake.ethStake.registeredStakerValidatorPubKey
      ? findValidatorByVotePubkey(
          validators,
          stake.ethStake.registeredStakerValidatorPubKey
        )
      : undefined);

  return {
    rewards,
    stakedAmount: stake.ethStake.stakedAmount,
    status: stake.status,
    validator,
    exception
  };
};

export const allowSolanaDelegateButton = (): boolean =>
  !!config.solana.waitBeforeAllowingManualStakeMs;
