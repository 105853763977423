import { EpochInfo, PublicKey } from '@solana/web3.js';
import { BigNumber } from '@ethersproject/bignumber';
import { Validator } from '../validator';
import { GlobalStakeParameters } from '../ethereum/stakeContract';
import { FeeData, TransactionReceipt } from '@ethersproject/providers';

export type StakeStatus =
  | 'UNKNOWN' // We have not yet looked up the status of this stake
  | 'WARMUP' // The stake is not yet fully delegated, and earning no rewards or incomplete rewards
  | 'DELEGATED' // The status is delegated to a validator and earning rewards
  | 'COOLDOWN_PLCHAIN' // The stake is in the process of being undelegated on PLChain
  | 'NOT_DELEGATED' // The stake is either undelegated post PLChain cooldown (awaiting the start of the unlock on ethereum) or not yet delegated (impossible in our use-case)
  | 'COOLDOWN_ETHEREUM' // The stake has been withdrawn back to coinbase on PLChain, and is in the unlock cooldown on Ethereum
  | 'UNLOCKED' // The unlock cooldown is complete on Ethereum
  | 'WITHDRAWN'; // The stake has been withdrawn on ethereum and is closed

// This is an enum, rather than a string, because it maps directly to integers on chain
export enum EthStakeStatus {
  NeverStaked,
  Deposited,
  Unstaked,
  Withdrawn
}

export type SolanaStakeStatus =
  | 'UNINITIALIZED'
  | 'NOT_DELEGATED'
  | 'WARMUP'
  | 'COOLDOWN'
  | 'DELEGATED';

export type StakeRequest = {
  validator?: Validator; // must be set on production. If missing, do not delegate the stake.
  amount: BigNumber;
  ethAddress: string;
  solAddress: PublicKey;
  ethStakeTransaction?: string;
};

export type StakeResponse = {
  stakeAccount: PublicKey;
  validator?: Validator;
  stakedAmount: BigNumber;
  totalAccountAmount: BigNumber;
  status: StakeStatus;
  estimatedTimeToActive?: number;
};

export type UndelegateResponse = {
  solStake: StakeResponse;
};

export type Balance = { amount: number };

export type UnlockRequest = {
  ethAddress: string;
  reward: BigNumber;
};

export type UnstakeRequest = {
  validator?: Validator; // must be set on production. If missing, do not delegate the stake.
  amount: BigNumber;
  ethAddress: string;
  solAddress: PublicKey;
};

export type EthStake = {
  approvedAmount: BigNumber;
  stakedAmount: BigNumber;
  rewards?: BigNumber;
  ethStakeTransaction?: string;
  // once the stake has been unlocked, this contains the fee incurred by the unlock transaction
  ethFee?: BigNumber;
  status: StakeStatus;
  unstakeTimestamp?: number;
  unstakeTransaction?: TransactionReceipt;
  registeredStaker?: PublicKey;
  registeredStakerValidatorPubKey?: PublicKey;
};

export type WithdrawProcessRequest = {
  ethWithdrawalTransaction?: string;
};

export type WithdrawState = {
  validator?: Validator;
  ethAddress: string;
  solAddress: PublicKey;
  ethWithdrawalTransaction?: string;
};

type ActiveProcess = {
  type: 'STAKE' | 'UNDELEGATE' | 'UNLOCK' | 'WITHDRAW';
  pendingTransaction?: string; // TODO add more complex type here indicating e.g. confirmation count, gas etc
  request: StakeRequest | WithdrawProcessRequest;
  userConfirm?: boolean; // non-existent by default, set to true when completion of an active process requires user confirmation
  showSpeedup?: boolean;
};

export type StakeException = {
  type: 'AmountMismatch'; // more here as needed
  description: string;
};

export const amountMismatchException: StakeException = {
  type: 'AmountMismatch',
  description:
    'The staked amount on Ethereum does not match the amount on Powerledger.'
};

export type SolanaStakeTrigger = 'N/A' | 'ENABLED' | 'IN PROGRESS';

export type StakeState = {
  status: StakeStatus; // The top-level stake status, combined from the solana and eth stake status
  loading: number;
  activeProcess?: ActiveProcess;
  solanaStake?: StakeResponse;
  ethStake: EthStake;
  error?: Error | string;
  solanaWallet?: PublicKey;
  ethWallet?: string;
  solanaStakeTrigger: SolanaStakeTrigger;
  globalStakeParameters?: GlobalStakeParameters;
  feeData?: FeeData;
  showStakingSuccessMessage?: boolean;
  waitingForMetamask?: boolean;
  epochInfo?: EpochInfo;
  pollingEnabled: boolean;
};

// automatically disable polling for tests
const defaultPollingEnabled = !(process.env.NODE_ENV == 'test');
export const initialStakeState: StakeState = {
  loading: 0,
  status: 'UNKNOWN',
  ethStake: {
    approvedAmount: BigNumber.from(0),
    stakedAmount: BigNumber.from(0),
    status: 'UNKNOWN'
  },
  solanaStakeTrigger: 'N/A',
  pollingEnabled: defaultPollingEnabled
};

// A user-friendly view of the stake state
export type StakeSummary = {
  stakedAmount: BigNumber; // minor POWR
  status: StakeStatus;
  rewards: BigNumber; // minor POWR
  validator?: Validator;
  exception: StakeException | null;
};
