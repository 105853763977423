import { BigNumber } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { PublicKey } from '@solana/web3.js';
import { StakeState, StakeRequest } from '../types';
import { Validator } from '../../validator';

export type ThunkConfig = { rejectValue: Error; state: { stake: StakeState } };

export type StakeActionPayload = {
  amount: BigNumber;
  provider: Web3Provider;
  validator: Validator;
};

export type UndelegateActionPayload = {
  timestamp: string;
  signature: string;
};

export type WithdrawActionPayload = {
  provider: Web3Provider;
};

export type StakeActionThunkConfig = ThunkConfig & {
  pendingMeta: StakeRequest;
};

type GetPendingMetaForStakeAction = (
  { arg }: { arg: StakeActionPayload },
  { getState }: { getState: () => { stake: StakeState } }
) => StakeRequest;

export const getPendingMetaForStakeAction: GetPendingMetaForStakeAction = (
  { arg },
  { getState }
) => {
  if (!getState().stake.ethWallet || !getState().stake.solanaWallet) {
    throw new Error('Missing wallets');
  }

  return {
    amount: arg.amount,
    validator: arg.validator,
    ethAddress: getState().stake.ethWallet as string,
    solAddress: getState().stake.solanaWallet as PublicKey
  };
};

const getMessage = (error: string | Error) =>
  typeof error !== 'string' ? error.message : error;

export const normaliseError = (error: string | Error | undefined): string =>
  error ? getMessage(error) : 'Unknown error';
