import { PublicKey } from '@solana/web3.js';
import { BigNumber } from 'ethers';
import * as api from '../api/staker';
import { enrichValidatorWithStakeData } from './ethereum/util';
import { Web3Provider } from '@ethersproject/providers';

export type Validator = {
  address: PublicKey;
  votePubkey: PublicKey;
  name?: string;
  website?: string;
  keybaseUsername?: string;
  totalStakeMinorPOWR?: BigNumber;
  commission: number; // in percentage
};

export const viewName = (validator: Validator): string =>
  validator.name || validator.keybaseUsername || validator.address.toBase58();

/**
 * Get the validators from the backend, then populate them with the total staked amount on ethereum
 * @param provider
 */
export const getValidators = async (
  provider: Web3Provider
): Promise<Validator[]> => {
  const validatorPromises = await api
    .getValidators()
    .then((v) => v.map(enrichValidatorWithStakeData(provider)));
  return Promise.all(validatorPromises);
};
