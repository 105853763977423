import React, { FC, useState } from 'react';
import { Validator } from '../../lib/validator';
import { AddressView } from './AddressView';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { ValidatorInfoPopUp } from '../modal/ValidatorInfoPopup';

const ValidatorText: FC<{ validator: Validator }> = ({ validator }) =>
  validator.name ? (
    <span>{validator.name}</span>
  ) : (
    <AddressView address={validator.address} />
  );

export const ValidatorShortView: FC<{ validator: Validator }> = ({
  validator
}) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div className="inline-flex items-center" onClick={() => setInfoOpen(true)}>
      <ValidatorInfoPopUp
        validator={validator}
        setOpen={setInfoOpen}
        open={infoOpen}
      />
      <ValidatorText validator={validator} />
      <QuestionMarkCircleIcon
        className="h-5 w-5 ml-2 cursor-pointer text-gray-400"
        aria-hidden="true"
        onClick={() => setInfoOpen(true)}
      />
    </div>
  );
};
