import { StakeState } from '../state/types';
import { calculateFees, calculateFeesFromWei, Fees } from './util';
import { providers } from 'ethers';
import { ZERO } from '../constants';
import { ethereumStakeUndelegated } from '../util';

/**
 * Load the fees incurred by the user by the unlock transaction.
 * @param state
 * @param provider
 */
export const calculateUnlockFees = async (
  state: StakeState | undefined,
  provider: providers.Provider
): Promise<Fees | undefined> => {
  // the fees have been locked in to the stake after the unlock transaction
  if (
    state &&
    ethereumStakeUndelegated(state) &&
    state.ethStake.ethFee &&
    state.ethStake.ethFee.gt(ZERO)
  ) {
    return calculateFeesFromWei(provider, state.ethStake.ethFee);
  }

  // the unlock transaction has not been executed yet, estimate the fees that would be incurred
  return (
    state?.globalStakeParameters &&
    calculateFees(
      provider,
      state.globalStakeParameters.unlockGasCost,
      state?.feeData
    )
  );
};
