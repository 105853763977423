import { ethers, providers } from 'ethers';
import { Contract, ContractInterface } from '@ethersproject/contracts';
import { PublicKey } from '@solana/web3.js';
import { BigNumber } from '@ethersproject/bignumber';
import {
  FeeData,
  TransactionReceipt,
  TransactionResponse,
  Web3Provider
} from '@ethersproject/providers';
import { Logger as EthLogger } from 'ethers/lib/utils';
import { EthStakeStatus, StakeStatus } from '../state/types';
import {
  convertEthToPOWR,
  getValidatorTotalStake,
  GlobalStakeParameters,
  StakeDetails
} from './stakeContract';
import { CHAIN_ID, WEI_PER_ETH } from '../constants';
import { bnDivide, remainingTime } from '../util';
import { EthereumNetwork } from '../config';
import { Validator } from '../validator';

export const getContract = (
  provider: providers.Provider,
  address: string,
  abi: ContractInterface
): Contract => new Contract(address, abi, provider);

// missing address values in the eth stake smart contract are represented as the empty string
export const stringToPubkey = (
  address: string | undefined
): PublicKey | undefined =>
  !address || address === '' ? undefined : new PublicKey(address);

// a funded wallet on goerli: 0x12ec04265E3135B6d9E1D1e661Ed6808046d90AB
export const fundedWallet = new ethers.Wallet(
  'c0e73ba8872b99394dad70c34ee39ebf9ce671a97bf3fc9c612fa3f1bfb70fe5'
);

export const onConfirm = (
  provider: providers.Provider,
  txHash: string,
  stopAfter: number,
  callback: (confirmations: number) => void
): void => {
  let lastConfirms = -1;
  const handler = async () => {
    const receipt = await provider.getTransactionReceipt(txHash);
    if (receipt?.confirmations) {
      // eth chain reorgs can cause the confirmation count to go backwards, but typically this will increase
      lastConfirms = receipt.confirmations;
    }

    callback(lastConfirms);
  };
  provider.on('block', handler);
};

export const extractMessageFromWeb3Error = (errorMessage: string): string => {
  // web3 errors are wrapped by a string like:
  // "<Top level error message> (error={<internal on-chain error message JSON>}, method=...)"
  const web3ErrorRegex = /\(error=({.*}), method=/;
  // the internal error message from the contract is structured as follows:
  // "execution reverted: <Contract>:<function>: <reason>"
  // We only want the last part
  const internalErrorRegex = /:\s*([^:]*$)/;
  const match = errorMessage.match(web3ErrorRegex);

  if (!match) return errorMessage;

  // attempt to parse the internal contract error message as JSON.
  // Just return the original error message if it fails.
  try {
    const internalError = JSON.parse(match[1]);
    const internalErrorMatch = internalError.message.match(internalErrorRegex);
    if (internalErrorMatch) {
      return internalErrorMatch[1];
    }
  } catch (error) {
    // ignore and fall-through
  }
  return errorMessage;
};

/**
 * Converts a given amount of POWR tokens (in their minor denomination) to Lamports
 * 1 POWR (6 decimal places) = 1 Solstice = 1e9 Lamports
 * @param amount
 */
export const powrToLamports = (amount: number | BigNumber): BigNumber =>
  BigNumber.from(amount).mul(1000);

/**
 * Converts a given amount of Lamports to POWR tokens (in their minor denomination)
 * 1 POWR (6 decimal places) = 1 Solstice = 1e9 Lamports
 * @param amount
 */
export const lamportsToMinorPowr = (amount: number | BigNumber): BigNumber =>
  BigNumber.from(amount).div(1000);

/**
 * Convert a POWR amount in its major denomination to its minor denomination (1e6)
 * @param amount
 */
export const majorPOWRToMinor = (amount: number): BigNumber =>
  BigNumber.from(Math.floor(amount * 1e6));

/**
 * Convert a POWR amount in its minor denomination to its major denomination (1e6)
 * Should be used when displaying the amount to the user only.
 * Large POWR values (beyond 1e15) cannot be displayed in major denomination
 * with full accuracy.
 * @param amount
 */
export const minorPOWRToMajor = (amount: number | BigNumber): number =>
  BigNumber.from(amount).toNumber() / 1e6;

export interface EthError extends Error {
  code?: string;
  replacement?: TransactionResponse;
}
const isReplacementTransactionError = (error: EthError) =>
  error.code === EthLogger.errors.TRANSACTION_REPLACED;

/**
 * This function will resolve a promise after the transaction has been confirmed,
 * or if it is replaced with a new transaction, assume it is the same tx sped up,
 * and wait for it instead
 * @param transactionResponse
 */
export const waitForTransaction = async (
  transactionResponse: TransactionResponse
): Promise<TransactionReceipt> =>
  transactionResponse.wait().catch((error) => {
    const ethError = error as EthError;
    if (
      ethError.code &&
      isReplacementTransactionError(ethError) &&
      ethError.replacement
    ) {
      // if the transaction was replaced, assume it is the same tx sped up,
      // and resolve when it has confirmed
      return waitForTransaction(ethError.replacement);
    } else {
      throw ethError;
    }
  });

const inCooldown = (stakeDetails: StakeDetails): boolean =>
  !!stakeDetails.unstakeTimestamp &&
  remainingTime(stakeDetails.unstakeTimestamp * 1000) > 0;

export const ethStakeDetailsToStatus = (
  stakeDetails: StakeDetails
): StakeStatus => {
  switch (stakeDetails.stakeStatus) {
    case EthStakeStatus.Deposited:
      return 'DELEGATED';
    case EthStakeStatus.Unstaked:
      return inCooldown(stakeDetails) ? 'COOLDOWN_ETHEREUM' : 'UNLOCKED';
    case EthStakeStatus.NeverStaked:
      return 'NOT_DELEGATED';
    case EthStakeStatus.Withdrawn:
      return 'WITHDRAWN';
  }
};

export const getFeeData = (provider: providers.Provider): Promise<FeeData> =>
  provider.getFeeData();

export type Fees = { eth: number; powr: number };

export const calculateFeesFromWei = async (
  provider: providers.Provider,
  feesInWei: BigNumber
): Promise<Fees> => {
  const feesInPOWRBase = await convertEthToPOWR(feesInWei, provider);

  const feesInEth = bnDivide(feesInWei, '' + WEI_PER_ETH);
  const feesInPOWR = minorPOWRToMajor(feesInPOWRBase);

  return {
    eth: feesInEth,
    powr: feesInPOWR
  };
};

export const calculateFees = async (
  provider: providers.Provider,
  gas: BigNumber,
  feeData: FeeData | undefined
): Promise<Fees | undefined> => {
  if (!feeData?.gasPrice) return undefined;

  // Calculate the gas price in eth by multiplying the gas required by the gas price
  // then dividing by 1e18 to convert from wei to eth.
  const feesInWei = feeData.gasPrice.mul(gas);
  return calculateFeesFromWei(provider, feesInWei);
};

export const switchNetwork = (
  provider: Web3Provider,
  requestNetwork: EthereumNetwork
) =>
  provider.send('wallet_switchEthereumChain', [
    { chainId: CHAIN_ID[requestNetwork] }
  ]);

export const signWithMetamask = async (
  provider: Web3Provider,
  message: string | ethers.utils.Bytes
): Promise<string> => {
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();
  return signer.signMessage(message);
};

export const validatorExceedsMaxStake = (
  validator: Validator,
  stakeMinorPowr: BigNumber,
  globalStakeParameters: GlobalStakeParameters
) =>
  validator.totalStakeMinorPOWR &&
  validator.totalStakeMinorPOWR
    .add(stakeMinorPowr)
    .gte(globalStakeParameters.maxPowrPerValidator);

export const enrichValidatorWithStakeData =
  (provider: Web3Provider) =>
  async (validator: Validator): Promise<Validator> => {
    const totalStakeMinorPOWR = await getValidatorTotalStake(
      provider,
      validator
    );
    return {
      ...validator,
      totalStakeMinorPOWR
    };
  };
