import { createContext, FC, useCallback, useContext, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { Validator } from '../validator';
import { initialStakeState, StakeState } from './types';
import { useAppDispatch, useAppSelector } from '../../store';
import { setWallets } from './slice';
import { loadSolanaStake } from './thunks/loadSolanaStake';
import { loadEthStake } from './thunks/loadEthStake';
import { stakeEth } from './thunks/stakeEth';
import { approveDeposit } from './thunks/approveDeposit';
import { stakeSol } from './thunks/stakeSol';
import { withdrawEth } from './thunks/withdrawEth';
import { SignerWallet } from '../withdraw';
import { undelegate } from './thunks/undelegate';
import { loadGlobalStakeParameters } from './thunks/loadGlobalStakeParameters';
import { loadFeeData } from './thunks/loadFeeData';
import { loadEpochInfo } from './thunks/loadEpochInfo';
import { unlock } from './thunks/unlock';

export type StakeContextProps = {
  stake?: StakeState;
  approveStake: (amount: BigNumber, validator: Validator) => void;
  depositStake: (amount: BigNumber, validator: Validator) => void;
  triggerSolanaStake: (amount: BigNumber, validator: Validator) => void;
  loadStake: () => void;
  triggerWithdrawEthereum: () => void;
  triggerUnlock: () => void;
  triggerUndelegate: (timestamp: string, signature: string) => void;
};
export const defaultStakeContextProps = {
  stake: initialStakeState,
  approveStake(): void {},
  depositStake(): void {},
  triggerSolanaStake(): void {},
  loadStake(): void {},
  triggerWithdrawEthereum(): void {},
  triggerUnlock(): void {},
  triggerUndelegate(): void {}
};
export const StakeContext = createContext<StakeContextProps>(
  defaultStakeContextProps
);

export const StakeProvider: FC = ({ children }) => {
  const wallet = useWallet();
  const dispatch = useAppDispatch();
  const state = useAppSelector((rootState) => rootState.stake);
  const {
    account,
    active: ethWalletConnected,
    library,
    chainId
  } = useWeb3React();

  const solWalletConnected = wallet?.connected;

  const loadStake = useCallback(() => {
    dispatch(loadSolanaStake());
    dispatch(loadEthStake(library));
  }, [dispatch, library]);

  // Load on startup
  useEffect(() => {
    dispatch(loadEpochInfo());
  }, []);

  // Load when the the and sol wallets are connected
  useEffect(() => {
    if (
      ethWalletConnected &&
      solWalletConnected &&
      account &&
      wallet.publicKey
    ) {
      dispatch(loadGlobalStakeParameters(library));
      dispatch(loadFeeData(library));
      dispatch(loadEpochInfo());
      dispatch(
        setWallets({
          solanaWallet: wallet.publicKey,
          ethWallet: account
        })
      );
      loadStake();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ethWalletConnected,
    solWalletConnected,
    wallet.publicKey,
    account,
    chainId
  ]);

  const approveStake = useCallback(
    (amount: BigNumber, validator: Validator) => {
      if (amount) {
        dispatch(
          approveDeposit({
            amount,
            provider: library,
            validator
          })
        );
      }
    },
    [dispatch, library]
  );

  const depositStake = useCallback(
    (amount: BigNumber, validator: Validator) => {
      if (amount && validator) {
        dispatch(
          stakeEth({
            amount,
            validator,
            provider: library
          })
        );
      }
    },
    [dispatch, library]
  );

  const triggerSolanaStake = useCallback(() => {
    if (wallet.publicKey && account) {
      dispatch(stakeSol());
    }
  }, [dispatch, wallet.publicKey, account]);

  // Trigger a withdraw of funds from the PLChain back to coinbase,
  // and an unlock of the stake on Ethereum
  const triggerUnlock = useCallback(() => {
    if (wallet && account) {
      dispatch(
        unlock({
          wallet: wallet as SignerWallet,
          stake: state.solanaStake,
          ethAddress: account,
          provider: library
        })
      );
    }
  }, [dispatch, state, wallet]);

  const triggerUndelegate = useCallback(
    (timestamp, signature) => {
      (async () => {
        if (state?.solanaStake?.stakeAccount && wallet && wallet.publicKey) {
          dispatch(undelegate({ timestamp, signature }));
        }
      })();
    },
    [dispatch, state, wallet]
  );

  // Trigger a withdrawal of funds on ethereum
  const triggerWithdrawEthereum = useCallback(() => {
    (async () => {
      if (account && wallet) {
        dispatch(
          withdrawEth({
            provider: library
          })
        );
      }
    })();
  }, [dispatch, account, wallet]);

  const props = {
    stake: state,
    loadStake,
    approveStake,
    depositStake,
    triggerSolanaStake,
    triggerUnlock,
    triggerUndelegate,
    triggerWithdrawEthereum
  };

  return (
    <StakeContext.Provider value={props}>{children}</StakeContext.Provider>
  );
};

export const useStake = (): StakeContextProps => useContext(StakeContext);
