/**
 * A general-purpose modal component for informational popups.
 */

import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'onOK'>;

const DefaultIcon = (
  <QuestionMarkCircleIcon
    className="h-12 w-12 text-red-700"
    aria-hidden="true"
  />
);

// Used for implementors - just includes open and setOpen
export type ResolvedProps = Omit<Props, 'title' | 'message'>;
export const InfoPopup: FC<Props> = ({
  message,
  title,
  children,
  Icon,
  ...props
}) => {
  const IconToShow = (
    <div className="mx-auto flex items-center justify-center h-12 w-12">
      {Icon || DefaultIcon}
    </div>
  );
  return (
    <Confirm
      {...props}
      message={message}
      title={title}
      okText="OK"
      onOK={() => {}}
      okOnly={true}
      Icon={IconToShow}>
      {children}
    </Confirm>
  );
};
