import erc20ABI from 'erc-20-abi';
import { providers, Signer } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import config from '../config';
import { getContract } from './util';

const erc20Contract = (provider: providers.Provider) =>
  getContract(provider, config.ethereum.contract.erc20, erc20ABI);

/**
 * Gets the current balance of POWR tokens in major denomination in a given wallet
 * @param provider
 * @param address
 */
export const getBalance = (
  provider: providers.Provider,
  address: string
): Promise<BigNumber> => {
  const powrERC20 = erc20Contract(provider);
  return powrERC20.balanceOf(address);
};

export const approveStake = (
  provider: providers.Provider,
  signer: Signer,
  amount: BigNumber
): Promise<TransactionResponse> => {
  const powrERC20 = erc20Contract(provider).connect(signer);
  return powrERC20.approve(config.ethereum.contract.stake, amount);
};

export const transfer = (
  provider: providers.Provider,
  signer: Signer,
  amount: BigNumber,
  recipient: string
): Promise<TransactionResponse> => {
  const powrERC20 = erc20Contract(provider).connect(signer);
  return powrERC20.transfer(recipient, amount);
};

export const getApprovedAmount = (
  provider: providers.Provider,
  address: string
): Promise<BigNumber> => {
  const powrERC20 = erc20Contract(provider);
  return powrERC20.allowance(address, config.ethereum.contract.stake);
};
