import { useStake } from '../../lib/state/context';
import { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { confirmCompletedProcess } from '../../lib/state/actions';
import { UndelegateCompleteConfirm } from '../modal/UndelegateCompleteConfirm';
import { UnlockCompleteConfirm } from '../modal/UnlockCompleteConfirm';

export const ConfirmProcessProvider: FC = ({ children }) => {
  const { stake } = useStake();
  const dispatch = useAppDispatch();
  const [processConfirmOpen, setProcessConfirmOpen] = useState(false);

  useEffect(() => {
    if (!!stake?.activeProcess?.userConfirm) {
      setProcessConfirmOpen(true);
    }
  }, [stake]);

  const triggerConfirm = useCallback(() => {
    (async () => {
      dispatch(confirmCompletedProcess());
    })();
  }, [dispatch]);

  const ConfirmDialog = useCallback(() => {
    if (stake?.activeProcess?.type === 'UNDELEGATE') {
      return (
        <UndelegateCompleteConfirm
          open={processConfirmOpen}
          setOpen={setProcessConfirmOpen}
          onOK={triggerConfirm}
        />
      );
    }
    if (stake?.activeProcess?.type === 'UNLOCK') {
      return (
        <UnlockCompleteConfirm
          open={processConfirmOpen}
          setOpen={setProcessConfirmOpen}
          onOK={triggerConfirm}
        />
      );
    }
    return <></>;
  }, [stake, dispatch]);

  return (
    <>
      <ConfirmDialog />
      {children}
    </>
  );
};
