import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { StakeSummary } from '../../lib/state/types';
import { ExclamationIcon } from '@heroicons/react/solid';
import * as React from 'react';

type Props = Omit<
  ConfirmProps,
  'okText' | 'cancelText' | 'message' | 'title' | 'onOK'
> & { stakeSummary: StakeSummary };
export const StakeExceptionPopup: FC<Props> = ({ stakeSummary, ...props }) => (
  <Confirm
    {...props}
    message={`Please contact Powerledger support with your wallet details and the following message: `}
    title="There is a problem with your stake"
    okText="OK"
    onOK={() => {}}
    okOnly={true}
    Icon={
      <div className="mx-auto flex items-center justify-center h-12 w-12">
        <ExclamationIcon
          className="h-12 w-12 text-red-700"
          aria-hidden="true"
        />
      </div>
    }>
    <div className="font-mono">{stakeSummary.exception?.description}</div>
  </Confirm>
);
