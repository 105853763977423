import { FC, useCallback, useState } from 'react';
import { DepositStake } from '../stake/DepositStake';
import { Stake } from '../stake/Stake';
import { useStake } from '../../lib/state/context';
import { ZERO } from '../../lib/constants';
import { useBalance } from '../../lib/hooks/useBalance';
import { ZeroBalance } from '../stake/ZeroBalance';
import { Loading } from '../common/Loading';
import { AwaitingStep } from '../stake/AwaitingStep';
import { getAwaitingStakeStep } from '../../lib/state/util';
import { StakeError } from '../stake/StakeError';
import { Withdrawal } from '../withdraw/Withdrawal';
import { ContinueWithdrawalConfirm } from '../modal/ContinueWithdrawalConfirm';
import { error } from '../../lib/state/actions';
import { useAppDispatch } from '../../store';
import { StakeWithdrawn } from '../stake/StakeWithdrawn';
import { Unlock } from '../withdraw/Unlock';

export const ViewStake: FC = () => {
  const dispatch = useAppDispatch();
  const { stake, triggerWithdrawEthereum } = useStake();
  const powrBalance = useBalance();
  const [withdrawConfirmOpen, setWithdrawConfirmOpen] = useState(false);

  const hasError = !!stake?.error;
  const stakedOnSolana = !!stake?.solanaStake?.stakedAmount?.gt(ZERO);
  const stakedOnEthereum =
    stake?.ethStake.stakedAmount?.gt(ZERO) &&
    stake?.ethStake.status !== 'WITHDRAWN';
  const stakedOnEthereumOnly = !!(stakedOnEthereum && !stakedOnSolana);
  const zeroPowr = powrBalance?.eq(ZERO);
  const awaitingStep = getAwaitingStakeStep(stake, stakedOnEthereumOnly);

  const handleRejectWithdraw = useCallback(() => {
    setWithdrawConfirmOpen(false);
    dispatch(error('Withdrawal rejected'));
  }, [dispatch, setWithdrawConfirmOpen]);

  const handleConfirmWithdraw = useCallback(() => {
    setWithdrawConfirmOpen(false);
    triggerWithdrawEthereum();
  }, [setWithdrawConfirmOpen]);

  const getComponent = () => {
    // Some error has been detected
    if (hasError) return <StakeError />;

    // An undelegate, unlock or withdrawal is in progress
    if (stake?.activeProcess?.type === 'UNDELEGATE')
      return (
        <div className="w-1/4">
          <Loading />
        </div>
      );
    if (stake?.activeProcess?.type === 'UNLOCK') return <Unlock />;
    if (stake?.activeProcess?.type === 'WITHDRAW') return <Withdrawal />;

    // A deposit is in progress
    if (stake?.activeProcess?.type === 'STAKE') {
      if (awaitingStep !== undefined) {
        return <AwaitingStep activeStep={awaitingStep} type="stake" />;
      }

      return (
        <div className="w-1/4">
          <Loading
            loadingText={
              stake?.waitingForMetamask ? 'Waiting for Metamask...' : undefined
            }
          />
        </div>
      );
    }

    // No POWR to stake
    if (zeroPowr && !stakedOnEthereum) return <ZeroBalance />;

    // Stake exists on Solana - in warmup, delegated or cooldown_plchain
    if (stakedOnSolana) return <Stake />;
    // Stake is in Unlocked or cooldown_eth state
    if (stakedOnEthereum && !stakedOnSolana) return <Stake />;

    // Stake has already been withdrawn - user cannot restake with the same wallet
    if (stake?.ethStake.status === 'WITHDRAWN') return <StakeWithdrawn />;

    // No stake exists - show the deposit page
    return <DepositStake />;
  };

  return (
    <div className="mx-auto flex justify-center">
      <ContinueWithdrawalConfirm
        open={withdrawConfirmOpen}
        setOpen={setWithdrawConfirmOpen}
        onOK={handleConfirmWithdraw}
        onCancel={handleRejectWithdraw}
      />
      {getComponent()}
    </div>
  );
};
