import { FC, useMemo } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { useUnlockFee } from '../../lib/hooks/useUnlockFee';
import { FeeView } from '../common/FeeView';
import * as React from 'react';

const UnlockMessage = () => (
  <>
    <p>Start cooldown of your stake?</p>
    <p>This triggers the first stage of the withdrawal process:</p>
    <p>Undelegating the stake on the Powerledger chain.</p>
    <p>
      For more information, see{' '}
      <a
        href="https://www.powerledger.io/staking"
        className="text-link-blue underline decoration-solid"
        target="_blank"
        rel="noreferrer">
        here
      </a>
    </p>
  </>
);

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const UndelegateStakeConfirm: FC<Props> = ({ ...props }) => {
  const { withdrawAllowed, fees } = useUnlockFee();

  const message = useMemo(
    () =>
      withdrawAllowed ? (
        <>
          <UnlockMessage />
          {fees && <FeeView fees={fees} />}
        </>
      ) : (
        <>
          <p>Start cooldown of your stake?</p>
          <p>Your stake will be available for withdrawal after ten days.</p>
          <p>
            Warning! Your stake balance does not yet exceed the unlock cost. You
            may not be able to withdraw the stake after the cooldown period.
          </p>
          {fees && <FeeView fees={fees} />}
        </>
      ),
    [fees, withdrawAllowed]
  );

  return <Confirm {...props} message={message} title="Confirm" />;
};
