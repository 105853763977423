import { FC } from 'react';
import { useStake } from '../../lib/state/context';
import { Loading } from '../common/Loading';
import { AwaitingStep } from '../stake/AwaitingStep';
import { getAwaitingWithdrawalStep } from '../../lib/state/util';
import { StakeError } from '../stake/StakeError';

export const Withdrawal: FC = () => {
  const { stake } = useStake();

  const hasError = !!stake?.error;
  const awaitingStep = getAwaitingWithdrawalStep(stake);

  const getComponent = () => {
    if (hasError) return <StakeError />;
    if (awaitingStep !== undefined)
      return <AwaitingStep activeStep={awaitingStep} type="withdraw" />;
    return (
      <div className="w-1/4">
        <Loading />
      </div>
    );
  };

  return <div className="mx-auto flex justify-center">{getComponent()}</div>;
};
