import { FC, useCallback, useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';
import { Validator } from '../../lib/validator';
import { ValidatorSelector } from './ValidatorSelector';
import { Button } from '../common/Button';
import { PowrAmountInput } from './PowrAmountInput';
import { MINIMUM_STAKE_LAMPORTS } from '../../lib/constants';
import { InitStakeConfirm } from '../modal/InitStakeConfirm';
import { useStake } from '../../lib/state/context';
import { maxBN } from '../../lib/util';
import {
  lamportsToMinorPowr,
  minorPOWRToMajor,
  validatorExceedsMaxStake
} from '../../lib/ethereum/util';
import { ValidatorMaxStakeExceededPopup } from '../modal/ValidatorMaxStakeExceededPopup';

const minimumSolanaStakeMinorPowr = lamportsToMinorPowr(MINIMUM_STAKE_LAMPORTS);

export const DepositStake: FC = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [
    validatorMaxStakeExceededPopupOpen,
    setValidatorMaxStakeExceededPopupOpen
  ] = useState(false);
  const [minimumStake, setMinimumStake] = useState(minimumSolanaStakeMinorPowr);
  const [validator, setValidator] = useState<Validator | undefined>();
  const [amount, setAmount] = useState<BigNumber>();
  const { library } = useWeb3React();
  const { stake, depositStake, approveStake } = useStake();

  const deposit = useCallback(() => {
    if (validator && amount && stake?.globalStakeParameters) {
      (async () => {
        if (
          validatorExceedsMaxStake(
            validator,
            amount,
            stake!.globalStakeParameters!
          )
        ) {
          setValidatorMaxStakeExceededPopupOpen(true);
          return;
        }

        if (stake?.ethStake?.approvedAmount?.gte(amount)) {
          depositStake(amount, validator);
        } else {
          approveStake(amount, validator);
        }
      })();
    }
  }, [stake, depositStake, approveStake, validator, amount]);

  const depositButtonTooltip =
    amount && amount.lt(minimumStake)
      ? `Minimum stake: ${minorPOWRToMajor(minimumStake)} POWR`
      : '';

  useEffect(() => {
    (async () => {
      if (stake?.globalStakeParameters) {
        setMinimumStake(
          maxBN(
            minimumSolanaStakeMinorPowr,
            stake.globalStakeParameters.minPowrDeposit
          )
        );
      }
    })();
  }, [library]);

  return (
    <div className="grid">
      <InitStakeConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onOK={deposit}
      />
      {amount && stake?.globalStakeParameters && validator && (
        <ValidatorMaxStakeExceededPopup
          open={validatorMaxStakeExceededPopupOpen}
          setOpen={setValidatorMaxStakeExceededPopupOpen}
          validator={validator}
          amount={amount}
          maxAmount={stake.globalStakeParameters.maxPowrPerValidator}
        />
      )}
      <PowrAmountInput amount={amount} setAmount={setAmount} />
      <ValidatorSelector selected={validator} setSelected={setValidator} />
      <div className="flex">
        <Button
          data-testid="deposit-button"
          title={depositButtonTooltip}
          disabled={!amount || amount.lt(minimumStake) || !validator}
          onClick={() => setConfirmOpen(true)}>
          Stake
        </Button>
      </div>
    </div>
  );
};
