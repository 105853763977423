import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionReceipt } from '@ethersproject/providers';
import {
  getPendingMetaForStakeAction,
  StakeActionPayload,
  StakeActionThunkConfig
} from './common';
import * as powrContract from '../../ethereum/powrContract';
import { stakeEth } from './stakeEth';
import { loadEthStake } from './loadEthStake';
import {
  ethTransactionConfirmed,
  ethTransactionBroadcast,
  ethTransactionTooSlow,
  waitingForMetamask
} from '../actions';
import { waitForTransaction } from '../../ethereum/util';

export const approveDeposit = createAsyncThunk<
  TransactionReceipt,
  StakeActionPayload,
  StakeActionThunkConfig
>(
  'eth/approve',
  async ({ amount, provider, validator }, { dispatch }) => {
    dispatch(waitingForMetamask(true));
    const response = await powrContract.approveStake(
      provider,
      provider.getSigner(),
      amount
    );
    dispatch(waitingForMetamask(false));

    dispatch(ethTransactionBroadcast(response.hash));

    // If the Tx is not complete in 90 seconds dispatch ethTransactionTooSlow
    const txTimer = setTimeout(() => {
      dispatch(ethTransactionTooSlow(true));
    }, 90000);

    const receipt = await waitForTransaction(response);

    clearTimeout(txTimer);

    dispatch(ethTransactionConfirmed(response.hash));

    await dispatch(loadEthStake(provider)).unwrap();

    dispatch(stakeEth({ amount, provider, validator }));

    return receipt;
  },
  // adds the stake request data to the pending action, so that the reducer can add it to the state
  {
    getPendingMeta: getPendingMetaForStakeAction
  }
);
