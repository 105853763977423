import { LoadingImage } from './LoadingImage';

export function Loading({
  loadingText = 'Loading...'
}: {
  loadingText?: string;
}) {
  return (
    <figure>
      <LoadingImage />
      <figcaption className="text-center font-body font-normal tracking-wider">
        {loadingText}
      </figcaption>
    </figure>
  );
}
