import { FC } from 'react';
import * as React from 'react';
import { InfoPopup } from './InfoPopup';
import { ResolvedProps as InfoProps } from './InfoPopup';
import { Validator, viewName } from '../../lib/validator';
import { BigNumber } from 'ethers';
import { minorPOWRToMajor } from '../../lib/ethereum/util';

type Props = InfoProps & {
  validator: Validator;
  amount: BigNumber;
  maxAmount: BigNumber;
};
export const ValidatorMaxStakeExceededPopup: FC<Props> = ({
  open,
  setOpen,
  validator,
  amount,
  maxAmount,
  children,
  ...props
}) => (
  <InfoPopup
    open={open}
    setOpen={setOpen}
    {...props}
    message={
      <>
        <p>Please select a different validator.</p>
        <p>
          In order to ensure decentralisation, the validator you have selected{' '}
          {viewName(validator)}
          has a maximum stake of {minorPOWRToMajor(maxAmount)} POWR.
        </p>
        <p>Your stake of {minorPOWRToMajor(amount)} POWR would exceed that.</p>
        <p>Please select another validator.</p>
      </>
    }
    title={'Validator Max Stake Exceeded'}>
    {children}
  </InfoPopup>
);
