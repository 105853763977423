/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, useCallback, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { classNames } from '../../lib/util';

const DefaultIcon = () => (
  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
  </div>
);

export type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onOK: () => void;
  onCancel?: () => void;
  title: string;
  message: string | JSX.Element;
  okText?: string;
  cancelText?: string;
  Icon?: JSX.Element;
  okOnly?: boolean;
  disabled?: boolean;
};
export const Confirm: FC<Props> = ({
  open,
  setOpen,
  onOK,
  onCancel = () => {}, // NOOP by default
  title,
  message,
  okText = 'I approve',
  cancelText = 'Cancel',
  Icon = <DefaultIcon />,
  okOnly = false,
  disabled = false,
  children
}) => {
  const cancelButtonRef = useRef(null);

  const ok = useCallback(() => {
    setOpen(false);
    onOK();
  }, [setOpen, onOK]);

  const cancel = useCallback(() => {
    setOpen(false);
    onCancel();
  }, [setOpen, onCancel]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        data-testid="confirm-dialog"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                {Icon}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div
                      className="text-sm text-gray-500"
                      data-testid="modal-message">
                      {message}
                    </div>
                    {children}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  'mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense',
                  okOnly ? 'sm:grid-cols-1' : 'sm:grid-cols-2'
                )}>
                <button
                  type="button"
                  data-testid="modal-ok-button"
                  disabled={disabled}
                  className={classNames(
                    'w-full inline-flex justify-center rounded-md border border-transparent',
                    'shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white sm:text-sm',
                    'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    okOnly ? '' : 'sm:col-start-2'
                  )}
                  onClick={ok}>
                  {okText}
                </button>
                {!okOnly && (
                  <button
                    type="button"
                    data-testid="modal-cancel-button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={cancel}
                    ref={cancelButtonRef}>
                    {cancelText}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
