import React, { FC, useMemo } from 'react';
import { calculateEpochProgressPercent, classNames } from '../../lib/util';
import { useStake } from '../../lib/state/context';

/**
 * Shows the current epoch progress as a percentage
 */
export const EpochProgressIndicator: FC = ({ ...props }) => {
  const { stake } = useStake();

  const epochProgress = useMemo(() => {
    if (!stake?.epochInfo) return undefined;

    return calculateEpochProgressPercent(stake.epochInfo);
  }, [stake?.epochInfo]);

  return epochProgress ? (
    <div
      className={classNames('text-sm ml-auto text-end grow mt-5 mr-5')}
      {...props}
      title="Epoch progress">
      Epoch {epochProgress}
    </div>
  ) : (
    <div className="flex-1"></div>
  );
};
