import { useWeb3React } from '@web3-react/core';
import { injected } from '../../lib/wallet/connectors/injected';
import { Button } from '../common/Button';
import { useCallback, useEffect, useState } from 'react';
import { InstallWalletConfirm } from '../modal/InstallWalletConfirm';

const isEthereumNotFoundError = (error?: Error) =>
  error?.name === 'NoEthereumProviderError';

export const EthereumConnector: React.FC = () => {
  const { active, activate, deactivate, error } = useWeb3React();
  const [isInstallWalletModalOpen, setInstallWalletModalOpen] = useState(false);

  const connect = useCallback(
    () => activate(injected).catch(console.error),
    [activate]
  );

  useEffect(() => {
    if (isEthereumNotFoundError(error)) {
      setInstallWalletModalOpen(true);
    }
  }, [error]);

  const disconnect = async () => {
    try {
      deactivate();
    } catch (deactivateError) {
      // eslint-disable-next-line no-console
      console.log(deactivateError);
    }
  };

  return (
    <div className="inline-flex items-center justify-center">
      <InstallWalletConfirm
        open={isInstallWalletModalOpen}
        setOpen={setInstallWalletModalOpen}
      />
      {active ? (
        <Button onClick={disconnect}>Disconnect Ethereum</Button>
      ) : (
        <Button onClick={connect}>Connect Ethereum</Button>
      )}
    </div>
  );
};
