import { FC, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { getBalance } from '../../lib/ethereum/powrContract';
import { majorPOWRToMinor, minorPOWRToMajor } from '../../lib/ethereum/util';
import { StakeAmountInfoPopup } from '../modal/StakeAmountInfoPopup';
import { InfoDialogIcon } from '../common/InfoDialogIcon';

type Props = {
  amount?: BigNumber;
  setAmount: (amount: BigNumber | undefined) => void;
  disabled?: boolean;
  allowDecimals?: boolean;
};
export const PowrAmountInput: FC<Props> = ({
  amount,
  setAmount,
  disabled = false,
  allowDecimals = false
}) => {
  const { library, account } = useWeb3React();
  const [max, setMax] = useState<BigNumber>();

  useEffect(() => {
    if (account && library && max === undefined) {
      getBalance(library, account).then((balance) => {
        setMax(balance);
        if (amount === undefined && amount !== balance) {
          // if an amount has not been set yet, set the value to the user's balance
          setAmount(balance);
        }
      });
    }
  }, [account, library, max, amount, setAmount]);

  return (
    <div className="inline-flex items-center justify-between mb-3">
      <span className="font-body font-medium">Stake</span>
      <InfoDialogIcon Popup={StakeAmountInfoPopup} />
      <div className="mt-1 ml-5 relative rounded-md shadow-sm">
        <input
          data-testid="powr-amount-input"
          disabled={disabled}
          type="number"
          min={allowDecimals ? '0.01' : '1'}
          step={allowDecimals ? '0.01' : '1'}
          name="account-number"
          id="account-number"
          className="
        rounded-lg w-56 h-12
        focus:ring-blue-300 focus:border-blue-300 block pr-10 sm:text-sm border-gray-300"
          value={amount ? '' + minorPOWRToMajor(amount) : ''}
          onChange={(event) => {
            if (event.target.value === '') {
              setAmount(undefined);
            } else {
              if (event.target.validity.valid) {
                const majorAmt = +event.target.value;
                const minorAmount = majorPOWRToMinor(majorAmt);
                setAmount(minorAmount);
              }
            }
          }}
        />
        <div
          className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer text-white"
          onClick={() => setAmount(max || BigNumber.from(0))}>
          <span className="bg-gray-300 hover:bg-gray-400 p-1 rounded-md font-mono font-bold">
            max
          </span>
        </div>
      </div>
    </div>
  );
};
