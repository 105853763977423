import { EpochInfo, PublicKey } from '@solana/web3.js';
import { once } from 'ramda';
import { BigNumber } from 'ethers';
import config from '../lib/config';
import { Validator } from '../lib/validator';
import {
  Balance,
  SolanaStakeStatus,
  StakeRequest,
  StakeResponse,
  UndelegateResponse
} from '../lib/state/types';
import { fetchAPI } from '../lib/util';
import {
  // createWithdrawTransaction,
  UnlockRequest,
  UnlockResponse
} from '../lib/withdraw';
import { UNDELEGATE_ACTION, UndelegateRequest } from '../lib/undelegate';
import { ZERO } from '../lib/constants';
import { powrToLamports } from '../lib/ethereum/util';
import { TransactionReceipt } from '@ethersproject/providers';
import { solToStakeStatus } from '../lib/solana/util';

type BlockhashResponse = {
  blockhash: string;
};

type ValidatorRaw = Validator & {
  address: string;
  votePubkey: string;
};

type RawStakeResponse = Omit<
  StakeResponse,
  | 'validator'
  | 'stakeAccount'
  | 'stakedAmount'
  | 'totalAccountAmount'
  | 'status'
> & {
  validator: string;
  stakeAccount: string;
  stakedAmount: number;
  totalAccountAmount: number;
  status: SolanaStakeStatus;
};

type UndelegateResponseRaw = {
  solStake: RawStakeResponse;
};

type UnlockResponseRaw = {
  solStake: RawStakeResponse;
  ethUnstakeTransaction: TransactionReceipt;
};

const toValidator = (validatorRaw: ValidatorRaw): Validator => ({
  ...validatorRaw,
  address: new PublicKey(validatorRaw.address),
  votePubkey: new PublicKey(validatorRaw.votePubkey)
});

const headers = {
  'Content-Type': 'application/json'
};

export const getValidators = once(
  (): Promise<Validator[]> =>
    fetchAPI(`${config.endpoint}/validator`)
      .then((res) => res.json())
      .then((validatorsRaw) => validatorsRaw.map(toValidator))
      .then((validators) =>
        validators.sort((a: Validator, b: Validator) =>
          (a.name || a.address.toString()).localeCompare(
            b.name || b.address.toString()
          )
        )
      )
);

const findValidatorByVotePubkey = async (
  votePubkey: PublicKey
): Promise<Validator | undefined> => {
  const validators = await getValidators();
  return validators.find((validator) =>
    validator.votePubkey.equals(votePubkey)
  );
};

const toStakeResponse = async (
  stakeResponseRaw: RawStakeResponse
): Promise<StakeResponse> => {
  const validatorPublicKey =
    stakeResponseRaw.validator && new PublicKey(stakeResponseRaw.validator);
  const matchingValidator =
    validatorPublicKey && (await findValidatorByVotePubkey(validatorPublicKey));

  // the validator is undefined if the stake is not delegated. If delegated, the validator is the validator that matches the stake
  // by votePubkey. If no validator matches by votePubkey, a dummy validator object is created, where the address is the votePubkey,
  // which is technically incorrect, but acceptable in the absence of other data.
  const validatorObject = validatorPublicKey
    ? matchingValidator || {
        votePubkey: validatorPublicKey,
        address: validatorPublicKey,
        commission: 0
      }
    : undefined;

  return {
    ...stakeResponseRaw,
    status: solToStakeStatus(stakeResponseRaw.status),
    validator: validatorObject,
    stakeAccount: new PublicKey(stakeResponseRaw.stakeAccount),
    stakedAmount: stakeResponseRaw.stakedAmount
      ? BigNumber.from(stakeResponseRaw.stakedAmount)
      : ZERO,
    totalAccountAmount: stakeResponseRaw.totalAccountAmount
      ? BigNumber.from(stakeResponseRaw.totalAccountAmount)
      : ZERO
  };
};

const toUndelegateResponse = async (
  undelegateResponseRaw: UndelegateResponseRaw
): Promise<UndelegateResponse> => {
  const stakeResponse = await toStakeResponse(undelegateResponseRaw.solStake);

  return {
    solStake: stakeResponse
  };
};

const arrayToStakeResponse = async (
  stakeResponsesRaw: RawStakeResponse[]
): Promise<StakeResponse | null> => {
  if (stakeResponsesRaw.length === 0) {
    return null;
  }
  const [stakeResponseRaw] = stakeResponsesRaw;
  return toStakeResponse(stakeResponseRaw);
};

const toStakeResponseWithRequest =
  (stakeRequest: StakeRequest) =>
  (stakeResponseRaw: RawStakeResponse): StakeResponse => ({
    ...stakeResponseRaw,
    status: solToStakeStatus(stakeResponseRaw.status),
    validator: stakeRequest.validator,
    stakeAccount: new PublicKey(stakeResponseRaw.stakeAccount),
    stakedAmount: stakeResponseRaw.stakedAmount
      ? BigNumber.from(stakeResponseRaw.stakedAmount)
      : ZERO,
    totalAccountAmount: stakeResponseRaw.totalAccountAmount
      ? BigNumber.from(stakeResponseRaw.totalAccountAmount)
      : ZERO
  });

const toUnlockResponse = async (
  unlockResponseRaw: UnlockResponseRaw
): Promise<UnlockResponse> => {
  const stakeResponse = await toStakeResponse(unlockResponseRaw.solStake);

  return {
    solStake: stakeResponse,
    transactionReceipt: unlockResponseRaw.ethUnstakeTransaction
  };
};

// Register a stake on PLChain
export const stake = (stakeRequest: StakeRequest): Promise<StakeResponse> => {
  const body = {
    ...stakeRequest,
    amount: powrToLamports(stakeRequest.amount).toString(),
    solAddress: stakeRequest.solAddress.toString(),
    validator: stakeRequest.validator?.votePubkey.toString()
  };

  return fetchAPI(`${config.endpoint}/stake`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then(toStakeResponseWithRequest(stakeRequest));
};

// Retrieve details of an existing stake owned by publicKey (if any)
export const getStake = (publicKey: PublicKey): Promise<StakeResponse | null> =>
  fetchAPI(`${config.endpoint}/stake/${publicKey.toString()}`)
    .then((res) => res.json())
    .then(arrayToStakeResponse);

export const getBalance = (publicKey: PublicKey): Promise<Balance | null> =>
  fetchAPI(`${config.endpoint}/balance/${publicKey.toString()}`).then((res) =>
    res.json()
  );

export const getRecentBlockhash = (): Promise<string> =>
  fetchAPI(`${config.endpoint}/chain/blockhash`)
    .then((res) => res.json() as Promise<BlockhashResponse>)
    .then(({ blockhash }) => blockhash);

export const getEpochInfo = (): Promise<EpochInfo> =>
  fetchAPI(`${config.endpoint}/chain/epoch`).then(
    (res) => res.json() as Promise<EpochInfo>
  );

// Undelegate a stake on PLChain (step 1 of the 3-step withdrawal process)
export const undelegate = (
  undelegateRequest: UndelegateRequest
): Promise<UndelegateResponse> =>
  fetchAPI(
    `${config.endpoint}/stake/${undelegateRequest.solAddress}/${undelegateRequest.stakeAccount}`,
    {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        action: UNDELEGATE_ACTION,
        ethAddress: undelegateRequest.ethAddress,
        timestamp: undelegateRequest.timestamp,
        signature: undelegateRequest.signature
      })
    }
  )
    .then((res) => res.json())
    .then(toUndelegateResponse);

/**
 * Send a request to the backend to unlock the stake (step 1 of the 3-step withdrawal process).
 * Unlock consists of:
 * 1. Withdrawing the Solstice on the PLChain back to the coinbase account
 * 2. Sending an unlock transaction on Ethereum
 * @param unlockRequest
 */
export const unlock = async (
  unlockRequest: Required<UnlockRequest>
): Promise<UnlockResponse> => {
  // const balance = await getBalance(unlockRequest.wallet.publicKey);
  // if there is no balance remaining in the staker account, the amount to transfer is zero
  // adding a zero-transfer instruction does nothing, but also costs nothing (at least in the
  // current version of solana) as it does not add any signatures to the transaction that are
  // not already there.
  // const { amount: remainingBalance } = balance || { amount: 0 };
  // const blockhash = await getRecentBlockhash();
  // const transaction = await createWithdrawTransaction(
  //   unlockRequest,
  //   blockhash,
  //   remainingBalance
  // );
  const url = `${config.endpoint}/stake/${unlockRequest.wallet.publicKey}/${unlockRequest.stake.stakeAccount}/tx`;

  return fetchAPI(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      action: 'UNLOCK',
      type: 'dummySignedSolWithdrawTx',
      associatedEthAddress: unlockRequest.ethAddress
    })
  })
    .then((res) => res.json())
    .then(toUnlockResponse);
};
