import { FC, useMemo } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { FeeView } from '../common/FeeView';
import { useUnlockFee } from '../../lib/hooks/useUnlockFee';
import * as React from 'react';
import { InfoDialogIcon } from '../common/InfoDialogIcon';
import { PreventWithdrawPopup } from './PreventWithdrawPopup';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const WithdrawStakeConfirm: FC<Props> = ({ ...props }) => {
  const { fees, withdrawAllowed } = useUnlockFee();

  const message = useMemo(
    () =>
      withdrawAllowed ? (
        <>
          <span>Please confirm you wish to withdraw your funds.</span>
          {fees && <FeeView fees={fees} />}
        </>
      ) : (
        <>
          <span>
            Unlock fee is too high to withdraw. Please contact Powerledger
            support.
          </span>
          <InfoDialogIcon Popup={PreventWithdrawPopup} />
          {fees && <FeeView fees={fees} />}
        </>
      ),
    [fees, withdrawAllowed]
  );

  return <Confirm {...props} message={message} title="Confirm Withdrawal" />;
};
