import { FC } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const UndelegateCompleteConfirm: FC<Props> = ({ ...props }) => {
  const message = <p>Your stake is undelegated and beginning cooldown.</p>;

  return (
    <Confirm
      {...props}
      message={message}
      title="Undelegate Step Complete"
      okText="OK"
      okOnly={true}
    />
  );
};
