// Action creators that are used in thunks, and are
// added here rather than defined in the slice, to avoid circular dependencies
import { createAction } from '@reduxjs/toolkit';
import { SolanaStakeTrigger } from './types';

export const ethTransactionBroadcast = createAction<string>(
  'stake/ethTransactionBroadcast'
);
export const ethTransactionTooSlow = createAction<boolean>(
  'stake/ethTransactionTooSlow'
);
export const ethTransactionConfirmed = createAction<string>(
  'stake/ethTransactionConfirmed'
);
export const triggerSolanaStake = createAction<SolanaStakeTrigger>(
  'stake/solanaStakeTrigger'
);
export const showStakingSuccessMessage = createAction<boolean | undefined>(
  'stake/showStakingSuccessMessage'
);
export const waitingForMetamask = createAction<boolean>(
  'stake/waitingForMetamask'
);

export const error = createAction<string | Error>('stake/error');

export const confirmCompletedProcess = createAction<void>(
  'stake/confirmCompletedProcess'
);
