import { FC } from 'react';
import logo from '../assets/images/north_star.svg';
import { NetworkIndicator } from './common/NetworkIndicator';
import { EpochProgressIndicator } from './common/EpochProgressIndicator';

export const Header: FC = () => (
  <div className="flex p-4 sm:p-6 lg:p-8">
    <a
      href="https://www.powerledger.io/"
      rel="noreferrer"
      target="_blank"
      tabIndex={-1}>
      <img src={logo} alt="logo" className="ml-auto h-16 sm:h-20 lg:h-24" />
    </a>
    <EpochProgressIndicator />
    <NetworkIndicator />
  </div>
);
