import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getValidators, Validator } from '../validator';

export const useValidators = (): Validator[] => {
  const [validators, setValidators] = useState<Validator[]>([]);
  const { library } = useWeb3React();

  useEffect(() => {
    if (library) {
      getValidators(library)
        .then(setValidators)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          setValidators([]);
        });
    }
  }, [library]);

  return validators;
};
