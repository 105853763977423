import { useEffect, useRef } from 'react';
import { identity, tap } from 'ramda';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import {
  COOLDOWN_TIME_MILLIS,
  ONE_DAY_MILLIS,
  ONE_HOUR_MILLIS,
  ONE_MINUTE_MILLIS,
  SOLANA_EPOCH_LENGTH_HOURS
} from './constants';
import { StakeState } from './state/types';
import { shouldLog } from './config';
import { EpochInfo } from '@solana/web3.js';

export const classNames = (...classes: (string | undefined)[]): string =>
  classes.filter(Boolean).join(' ');

export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const maxBN = (...values: BigNumber[]): BigNumber =>
  values.reduce((acc, val) => (acc.gt(val) ? acc : val), BigNumber.from(0));

/**
 * Divide a bignumber by another, returning a decimal to a given precision.
 * This is used instead of a.toNumber() / b for large values of a
 */
export const bnDivide = (
  a: BigNumber,
  b: BigNumberish,
  precision = 10
): number =>
  a
    .mul(10 ** precision)
    .div(b)
    .toNumber() /
  10 ** precision;

// Do not log in production
export const tapLog =
  // eslint-disable-next-line no-console
  shouldLog() ? tap(console.log) : identity;

export const handleAPIError = (response: Response): Response => {
  if (response.ok) return response;

  throw new Error(response.statusText);
};

export const fetchAPI = (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => fetch(input, init).then(tapLog).then(handleAPIError);

export const copyToClipboard = (text: string) =>
  navigator.clipboard.writeText(text);

/**
 * Converts a duration in milliseconds to a time period in the form:
 * x days, y hours (rounded up to the nearest hour)
 * @param timeMillis
 */
export const timeToText = (timeMillis: number): string => {
  if (timeMillis < 0) return '0 hours';

  const days = Math.floor(timeMillis / ONE_DAY_MILLIS);

  const remainderFromDays = timeMillis - days * ONE_DAY_MILLIS;
  const hours = Math.floor(remainderFromDays / ONE_HOUR_MILLIS);

  const remainderFromHours = remainderFromDays - hours * ONE_HOUR_MILLIS;
  const minutes = Math.ceil(remainderFromHours / ONE_MINUTE_MILLIS);

  const daysString = days ? `${days} day${days != 1 ? 's' : ''}, ` : '';
  const hoursString =
    hours || days ? `${hours} hour${hours != 1 ? 's' : ''}, ` : '';
  const minutesString = `${minutes} minute${minutes != 1 ? 's' : ''}`;

  return daysString + hoursString + minutesString;
};

/**
 * Checks if the ethereum stake has been undelegated. This should be checked before evaluating
 * values on the smart contract that are zero by default, such as fees or rewards.
 * @param stake
 */
export const ethereumStakeUndelegated = (stake: StakeState): boolean =>
  ['COOLDOWN_ETHEREUM', 'UNLOCKED'].includes(stake.ethStake.status);

/**
 * Given epoch info, return the proportion of the epoch that has passed
 * @param epochInfo
 */
const calculateEpochProgress = (epochInfo: EpochInfo): number =>
  epochInfo.slotIndex / epochInfo.slotsInEpoch;

/**
 * Given epoch info, return the percentage of the epoch that has passed as a string.
 * @param epochInfo
 */
export const calculateEpochProgressPercent = (epochInfo: EpochInfo): string =>
  `${(calculateEpochProgress(epochInfo) * 100).toFixed(2)}%`;

export const remainingTimeInEpoch = (epochInfo: EpochInfo): number => {
  const epochProgress = calculateEpochProgress(epochInfo);
  return SOLANA_EPOCH_LENGTH_HOURS * (1 - epochProgress) * ONE_HOUR_MILLIS;
};

// Time between unstake and cooldown complete in milliseconds
export const remainingTime = (startTimeMillis: number): number => {
  const diff = startTimeMillis + COOLDOWN_TIME_MILLIS - Date.now();
  return Math.max(diff, 0);
};
