import { FC, useCallback, useEffect, useState } from 'react';
import { Loading } from '../common/Loading';
import { StakeStep, UnlockStep, WithdrawStep } from '../../lib/state/util';
import { TriggerSolanaStake } from './TriggerSolanaStake';
import { useStake } from '../../lib/state/context';
import { useWeb3React } from '@web3-react/core';
import { EthereumNetwork } from '../../lib/config';

const nameMap: Record<string, EthereumNetwork> = {
  // Ethers reports mainnet's chain as homestead, which is not meaningful to most users.
  homestead: 'mainnet'
};

const stepDescriptions = {
  stake: [
    '1. Approving stake request',
    '2. Staking',
    '3. Waiting for Powerledger'
  ],
  unlock: ['1. Withdrawing on Powerledger', '2. Unlocking on Ethereum'],
  withdraw: ['Withdrawing on Ethereum']
};

const activeStyles = '';
const inactiveStyles = 'text-blue-900 text-opacity-50';
const Step: FC<{ text: string; active: boolean }> = ({ text, active }) => {
  return (
    <div className={`step ${active ? activeStyles : inactiveStyles}`}>
      <span className="inline-block w-3 text-center">{active ? '➣' : ' '}</span>{' '}
      <span className="step-text text-color inherited">{text}</span>
    </div>
  );
};

export const AwaitingStep: FC<{
  activeStep: StakeStep | UnlockStep | WithdrawStep;
  type: keyof typeof stepDescriptions;
}> = ({ activeStep, type }) => {
  const { stake } = useStake();
  const { library } = useWeb3React();
  const [network, setNetwork] = useState<EthereumNetwork>();
  const showTriggerSolanaStake = stake?.solanaStakeTrigger !== 'N/A';
  const showSpeedupBlurb = stake?.activeProcess?.showSpeedup;
  const pendingTxHash = stake?.activeProcess?.pendingTransaction;

  const updateNetwork = useCallback(
    (rawNetwork: string | undefined) => {
      const convertedNetwork: EthereumNetwork | undefined = (
        rawNetwork ? nameMap[rawNetwork] || rawNetwork : rawNetwork
      ) as EthereumNetwork | undefined;
      setNetwork(convertedNetwork);
    },
    [setNetwork]
  );

  // set the network name whenever the library (aka web3 provider) changes
  useEffect(() => {
    if (!library || typeof library.getNetwork !== 'function') {
      updateNetwork(undefined);
    } else {
      library.getNetwork().then(({ name }: { name?: string }) => {
        updateNetwork(name);
      });
    }
  }, [library, updateNetwork]);

  return (
    <div className="">
      <div className="w-1/3 justify-center mx-auto">
        <Loading />
      </div>
      <div className="justify-center mx-auto grid mt-4 mb-4">
        {stepDescriptions[type].map((text, index) => (
          <Step key={index} text={text} active={index === activeStep} />
        ))}
      </div>
      {showTriggerSolanaStake &&
        activeStep === StakeStep.WAITING_FOR_PLCHAIN && <TriggerSolanaStake />}
      {showSpeedupBlurb && pendingTxHash && (
        <div className="flex flex-col justify-center">
          <div className="font-normal text-center">
            This transaction appears to be taking a while. Consider speeding it
            up in Metamask.
          </div>
          <a
            href={`https://${
              network === 'mainnet' ? '' : `${network}.`
            }etherscan.io/tx/${pendingTxHash}`}
            rel="noreferrer"
            target="_blank"
            className="inline-block text-center text-link-blue">
            View on Etherscan
          </a>
        </div>
      )}
    </div>
  );
};
