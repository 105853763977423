import { createAsyncThunk } from '@reduxjs/toolkit';
import { StakeResponse } from '../types';
import { ThunkConfig } from './common';
import * as api from '../../../api/staker';

export const loadSolanaStake = createAsyncThunk<
  StakeResponse | null,
  void,
  ThunkConfig
>('solana/loadStake', (_, { getState }) => {
  const { solanaWallet } = getState().stake;
  if (!solanaWallet) {
    return Promise.reject(new Error('No wallet selected'));
  }
  return api.getStake(solanaWallet);
});
