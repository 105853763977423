import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig, UndelegateActionPayload } from './common';
import * as api from '../../../api/staker';
import { UndelegateResponse } from '../types';

export const undelegate = createAsyncThunk<
  UndelegateResponse,
  UndelegateActionPayload,
  ThunkConfig
>('solana/undelegate', async ({ timestamp, signature }, { getState }) => {
  const { stakeAccount } = getState().stake.solanaStake || {};
  const { solanaWallet: solAddress, ethWallet: ethAddress } = getState().stake;

  if (!stakeAccount || !solAddress || !ethAddress) {
    throw new Error('No stake account, solAddress or ethAddress in state');
  }

  return api.undelegate({
    stakeAccount,
    ethAddress,
    solAddress,
    timestamp,
    signature
  });
});
