import { providers, Signer } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';
import { PublicKey } from '@solana/web3.js';
import { TransactionResponse } from '@ethersproject/providers';
import { getContract, stringToPubkey } from './util';
import config from '../config';
import stakingABI from '../../contracts/StakingContractV1.json';
import { EthStakeStatus, StakeRequest } from '../state/types';
import { ZERO } from '../constants';
import { Validator } from '../validator';

const stakingContract = (provider: providers.Provider) =>
  getContract(provider, config.ethereum.contract.stake, stakingABI);

export type StakeDetails = {
  stake: BigNumber;
  stakeRewards?: BigNumber;
  ethFee: BigNumber;
  unstakeTimestamp?: number;
  stakeStatus: EthStakeStatus;
  registeredStaker?: PublicKey; // The Solana address of the staker
  registeredStakerValidatorPubKey?: PublicKey; // The Solana address of the validator
};

type RawStakeDetails = Omit<
  StakeDetails,
  | 'registeredStaker'
  | 'registeredStakerValidatorPubKey'
  | 'unstakeTimestamp'
  | 'stakeRewards'
> & {
  registeredStaker: string; // The Solana address of the staker (empty string if there is no stake)
  registeredStakerValidatorPubKey: string; // The Solana address of the validator (empty string if there is no stake)
  unstakeTimestamp: BigNumber;
  stakeRewards: BigNumber; // The stake rewards assigned to the ethereum stake, if present, 0 if not
};

const unstakedOrWithdrawn = (rawStakeDetails: RawStakeDetails) =>
  [EthStakeStatus.Unstaked, EthStakeStatus.Withdrawn].includes(
    rawStakeDetails.stakeStatus
  );

// return the stake rewards as specified by the ethereum smart contract.
// if the rewards are zero, the stake is either not unstaked yet (return no rewards)
// or it was unstaked before rewards were accrued (return zero)
const getStakeRewardsFromRawStake = (
  rawStakeDetails: RawStakeDetails
): BigNumber | undefined =>
  rawStakeDetails.stakeRewards.eq(ZERO) && !unstakedOrWithdrawn(rawStakeDetails)
    ? undefined
    : rawStakeDetails.stakeRewards;

const fromRawStakeDetails = (
  rawStakeDetails: RawStakeDetails
): StakeDetails => ({
  ...rawStakeDetails,
  registeredStaker: stringToPubkey(rawStakeDetails.registeredStaker),
  registeredStakerValidatorPubKey: stringToPubkey(
    rawStakeDetails.registeredStakerValidatorPubKey
  ),
  unstakeTimestamp: rawStakeDetails.unstakeTimestamp?.toNumber(),
  stakeRewards: getStakeRewardsFromRawStake(rawStakeDetails)
});

export type GlobalStakeParameters = {
  stakeCount: BigNumber; // number of people who are currently staking
  totalStaked: BigNumber; // total amount of POWR staked
  minPowrDeposit: BigNumber; // minimmum POWR deposit
  maxPowrPerValidator: BigNumber; // maximum allowable powr that can be delegated to each validator
  powrRatio: BigNumber; // 0 <= powrRatio <= 10000. Withdrawn POWR is multiplied by powrRatio/10000 to allow for 0.00%-100.00% conversion - see POWR_DIVISOR
  powrEthPool: string; // address of the uniswap v2 eth-powr pool
  unlockGasCost: BigNumber; // the cost of the unlock transaction (charged to the user)
};

/**
 * Gets the details of a stake for a given wallet
 * @param provider
 * @param address
 */
export const getStakeDetails = (
  provider: providers.Provider,
  address: string
): Promise<StakeDetails> => {
  const stakeContract = stakingContract(provider);
  return stakeContract.stkData(address).then(fromRawStakeDetails);
};

/**
 * Get global information about the staking contract
 * @param provider
 */
export const getGlobalStakeParameters = (
  provider: providers.Provider
): Promise<GlobalStakeParameters> => {
  const stakeContract = stakingContract(provider);
  return stakeContract.stkOpsData();
};

/**
 * Initiate a deposit transaction on the ethereum chain
 * @param provider
 * @param signer
 * @param request a stake request object
 */
export const stakeTokens = (
  provider: providers.Provider,
  signer: Signer,
  request: StakeRequest
): Promise<TransactionResponse> => {
  const stakeContract = stakingContract(provider).connect(signer);

  return stakeContract.deposit(
    request.amount,
    request.solAddress.toString(),
    request.validator?.votePubkey.toString()
  );
};

/**
 * Estimate the gas required to stake
 * @param provider
 * @param signer
 * @param request
 */
export const estimateGasStakeTokens = (
  provider: providers.Provider,
  signer: Signer,
  request: StakeRequest
): Promise<BigNumber> => {
  const stakeContract = stakingContract(provider).connect(signer);

  return stakeContract.estimateGas.deposit(
    request.amount,
    request.solAddress.toString(),
    request.validator?.votePubkey.toString()
  );
};

/**
 * Initiate a withdrawal transaction on the ethereum chain
 * @param provider
 * @param signer
 */
export const withdrawStake = (
  provider: providers.Provider,
  signer: Signer
): Promise<TransactionResponse> => {
  const stakeContract = stakingContract(provider).connect(signer);

  return stakeContract.withdraw();
};

/**
 * Estimate the gas required to withdraw a stake
 * @param provider
 * @param signer
 */
export const estimateGasWithdrawStake = (
  provider: providers.Provider,
  signer: Signer
): Promise<BigNumber> => {
  const stakeContract = stakingContract(provider).connect(signer);

  return stakeContract.estimateGas.withdraw();
};

export const getAssignedUnlockGasCost = (
  provider: providers.Provider,
  signer: Signer
): Promise<BigNumber> => {
  const stakeContract = stakingContract(provider).connect(signer);
  return stakeContract.unlockGasCost();
};

export const convertEthToPOWR = (
  eth: BigNumber,
  provider: providers.Provider
): Promise<BigNumber> => {
  const stakeContract = stakingContract(provider);

  return stakeContract.convertEthToPOWR(eth);
};

export const getValidatorTotalStake = (
  provider: providers.Provider,
  validator: Validator
): Promise<BigNumber> => {
  const stakeContract = stakingContract(provider);
  return stakeContract.validatorTotalStake(validator.votePubkey.toBase58());
};
