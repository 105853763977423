import { FC, useMemo } from 'react';
import { Confirm, Props as ConfirmProps } from './Confirm';
import { useStake } from '../../lib/state/context';
import * as React from 'react';
import { useEthereumExplorer } from '../../lib/hooks/useEthereumExplorer';

type Props = Omit<ConfirmProps, 'okText' | 'cancelText' | 'message' | 'title'>;
export const UnlockCompleteConfirm: FC<Props> = ({ ...props }) => {
  const { stake } = useStake();
  const txHash = useMemo(
    () => stake?.ethStake.unstakeTransaction?.transactionHash,
    [stake]
  );
  const url = useEthereumExplorer(txHash);

  const message = useMemo(
    () => (
      <>
        <p>The unlock process has been triggered.</p>
        <p>Your stake will be available for withdrawal after seven days.</p>
        {url && (
          <a href={url} className="text-link-blue underline decoration-solid">
            View unlock transaction
          </a>
        )}
      </>
    ),
    [txHash]
  );

  return (
    <Confirm
      {...props}
      message={message}
      title="Unlock Triggered"
      okText="OK"
      okOnly={true}
    />
  );
};
